import { createContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../constants/theme';
import useSession from '../hooks/useSession';
import { User } from '../types/User';
import { UsersApi } from '../api/users';

export const UserContext = createContext<User | null>(null);

export const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ${theme.colors.main};
`;

export const UserProvider = ({ children }) => {
    const { user: session } = useSession();
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        if (!session || !session?.uid) return;
        UsersApi.GetById(session?.uid)
            .then((x) => setUser(x))
            .catch((error) => console.log(error));
    }, [session]);

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
};