
import { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import useText from "../hooks/useText";
import Wrapper from "../components/wrapper";
import Box from "../components/box";
import theme from "../constants/theme";
import { Helmet } from 'react-helmet';

export const Content = styled.div``;

export const Title = styled.div`
    font-size: 30px;
    @media(max-width: 600px){
        font-size: 18px;
    }
`;

export const NumberFounds = styled.div`
    font-size: 24px;
    color: ${theme.colors.grey};
    @media(max-width: 600px){
        font-size: 16px;
    }
`;

export const List = styled.div`
    padding: 24px 0;
`;

export const Text = styled(Form.Text)`
    display: block;
`;

export const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
`;

export const WrapperBox = styled.div`
    margin-bottom: 12px; 
`

export default function Search() {
    const { loaded, search } = useText();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const word = searchParams.get("word");
    const [list, setList] = useState<any>([]);

    useEffect(() => {
        if (!word || !loaded) return;
        setList(search(word));
        // eslint-disable-next-line
    }, [word, loaded]);

    return (
        <Wrapper>
            <Helmet>
                <title>Buscador</title>
                <meta name="description" content="Buscador" />
            </Helmet>
            <Content>
                <Title>Paraula cercada: <b>{word}</b></Title>
                <NumberFounds>S'han trobat {list.length} coincidencies</NumberFounds>
                <List>
                    {list.map((copy: any, index: number) =>
                        <WrapperBox key={index}>
                            <Box >
                                <Link onClick={() => navigate(copy.url)}>
                                    {copy.title}
                                </Link>
                                <Text>{copy.text}</Text>
                            </Box>
                        </WrapperBox>
                    )}
                </List>
            </Content>
        </Wrapper>
    );
}
