import { useEffect, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Auth } from '../../../api/auth';
import theme from '../../../constants/theme';
import { RoleEnum } from '../../../constants/enums';
import { UsersApi } from '../../../api/users';

const Content = styled.div``;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 12px;
`;

const FormWrapper = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding-bottom: 12px;
`;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
`;

export interface UserAddProps {
    onCreate: () => void;
}


export function UserAdd(props: UserAddProps) {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [role, setRole] = useState<number>(0);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (!success) return;
        let timer = setTimeout(() => setSuccess(false), 2000);
        return () => { clearTimeout(timer); };
    }, [success]);

    useEffect(() => {
        if (!error) return;
        let timer = setTimeout(() => setError(false), 2000);
        return () => { clearTimeout(timer); };
    }, [error]);

    const handleSave = async () => {
        Auth.SignInEmailAndPassword(email, password)
            .then((cred: any) => {
                UsersApi.Create({ id: cred.user?.uid, email: email, name: name, role: role })
                    .then(() => {
                        setSuccess(true);
                        setPassword('');
                        setName('');
                        setEmail('');
                        setRole(0);
                        props.onCreate();
                    })
                    .catch((error) => setError(error));
            })
            .catch((error) => setError(error));
    };

    return (<Content>
        <Title>Nou</Title>
        <FormWrapper>
            <Form.Control
                type="text"
                placeholder="nom"
                value={name}
                onChange={e => setName(e.target.value)} />
            <Form.Control
                type="email"
                placeholder="email"
                value={email}
                onChange={e => setEmail(e.target.value)} />
            <Form.Control
                type="password"
                placeholder="contrasenya"
                value={password}
                onChange={e => setPassword(e.target.value)} />
            <Form.Select onChange={(e) => setRole(parseInt(e.target.value))}>
                <option value={RoleEnum.default} >Seleccionar</option>
                <option value={RoleEnum.superadmin} >SuperAdmin</option>
                <option value={RoleEnum.secretaria} >Secretaria</option>
                <option value={RoleEnum.direccio} >Direcció</option>
            </Form.Select>
            <ButtonStyled onClick={handleSave}>
                Guardar
            </ButtonStyled>
        </FormWrapper>
        {error && <Alert variant="danger">Error al guardar el nou usuari</Alert>}
        {success && <Alert variant="success">Se ha insertar correctamente un nou usuari</Alert>}
    </Content>);
}

