
import styled from "styled-components";
import WrapperPage from '../components/wrapper-page';
import { Helmet } from 'react-helmet';

const Content = styled.div`
    display: flex; 
    justify-content: center; 
    align-items: center;
    min-height: 700px;
`;

const Title = styled.div`
    font-size: 80px;
    text-align: center;
    line-height: normal;
`;

const Subtitle = styled.div`
    font-size: 24px;
`;

function NotFound() {
    return (
        <Content>
            <Helmet>
                <title>Página no trobada 404</title>
                <meta name="robots" content="noindex, nofollow" />
                <meta name="description" content="Página no trobada 404" />
            </Helmet>
            <WrapperPage>
                <Title>404</Title>
                <Subtitle>Página no trobada </Subtitle>
            </WrapperPage>
        </Content>
    );
}

export default NotFound;