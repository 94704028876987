
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styled from "styled-components";
import { ROUTES } from '../constants/routes';
import { useLocation, useNavigate } from 'react-router-dom';


const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 18px;

    @media(max-width: 930px){
        flex-direction: column;
        align-items: start;
    }
`;

const NavLinkStyled = styled(Nav.Link) <{ selected: boolean }>`
    color: ${props => props.selected ? '#c82d2d' : '#343a40'} !important;
    font-size: 16px;
`;

const NavDropdownStyled = styled(NavDropdown) <{ selected: boolean }>`
    color: ${props => props.selected ? '#c82d2d' : '#343a40'};
    font-size: 16px;
`;

const NavDropdownItemStyled = styled(NavDropdown.Item) <{ selected: boolean }>`
    color: ${props => props.selected ? '#c82d2d' : '#343a40'};
    font-size: 16px;
    &:active{
        background-color: #c82d2d;
    }
`;

/*const NavDropdownItemSupply = styled.div`
    padding: 4px 16px;
`;*/


interface HeaderPropsRoutes {
    handleCloseMobile?: () => void;
}

function HeaderRoutes(props: HeaderPropsRoutes) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (route: string) => {
        if (props.handleCloseMobile)
            props.handleCloseMobile();
        navigate(route);
    }

    return (
        <Content>
            <NavDropdownStyled
                title="El centre"
                selected={
                    ROUTES.Historia === location.pathname ||
                    ROUTES.PoliticaQualitat === location.pathname ||
                    ROUTES.DocumentacioEstrategica === location.pathname}>
                <NavDropdownItemStyled
                    selected={ROUTES.Historia === location.pathname}
                    onClick={() => handleClick(ROUTES.Historia)}>
                    Història
                </NavDropdownItemStyled>
                <NavDropdownItemStyled
                    selected={ROUTES.PoliticaQualitat === location.pathname}
                    onClick={() => handleClick(ROUTES.PoliticaQualitat)}>
                    Política de qualitat
                </NavDropdownItemStyled>
                <NavDropdownItemStyled
                    selected={ROUTES.DocumentacioEstrategica === location.pathname}
                    onClick={() => handleClick(ROUTES.DocumentacioEstrategica)}>
                    Documentació estratègica
                </NavDropdownItemStyled>
            </NavDropdownStyled>
            <NavDropdownStyled
                title="Oferta educativa"
                selected={
                    ROUTES.OfertaEducativaESO === location.pathname ||
                    ROUTES.OfertaEducativaCFGM === location.pathname ||
                    ROUTES.OfertaEducativaCFGS === location.pathname ||
                    ROUTES.OfertaEducativaBatxillerat === location.pathname}>
                <NavDropdownItemStyled
                    selected={ROUTES.OfertaEducativaBatxillerat === location.pathname}
                    onClick={() => handleClick(ROUTES.OfertaEducativaESO)}>
                    ESO
                </NavDropdownItemStyled>
                <NavDropdownItemStyled
                    selected={ROUTES.OfertaEducativaBatxillerat === location.pathname}
                    onClick={() => handleClick(ROUTES.OfertaEducativaBatxillerat)}>
                    Batxillerat
                </NavDropdownItemStyled>
                <NavDropdownItemStyled
                    selected={ROUTES.OfertaEducativaCFGM === location.pathname}
                    onClick={() => handleClick(ROUTES.OfertaEducativaCFGM)}>
                    CFGM
                </NavDropdownItemStyled>
                <NavDropdownItemStyled
                    selected={ROUTES.OfertaEducativaCFGS === location.pathname}
                    onClick={() => handleClick(ROUTES.OfertaEducativaCFGS)}>
                    CFGS
                </NavDropdownItemStyled>
            </NavDropdownStyled>
            <NavDropdownStyled
                title="Secretaria"
                selected={
                    ROUTES.Tramits === location.pathname ||
                    location.pathname.includes(ROUTES.PreinscripcioMatricula)}>
                <NavDropdownItemStyled
                    selected={ROUTES.Tramits === location.pathname}
                    onClick={() => handleClick(ROUTES.Tramits)}>
                    Tràmits
                </NavDropdownItemStyled>
                <NavDropdownItemStyled
                    selected={location.pathname.includes(ROUTES.PreinscripcioMatricula)}
                    onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/eso`)}>
                    Preinscripció i Matrícula
                </NavDropdownItemStyled>
                {/*<NavDropdownItemSupply>
                    <NavDropdownStyled
                        title="Preinscripció i Matrícula"
                        selected={location.pathname.includes(ROUTES.PreinscripcioMatricula)}>
                        <NavDropdownItemStyled
                            selected={`${ROUTES.PreinscripcioMatricula}/eso` === location.pathname}
                            onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/eso`)}>
                            ESO
                        </NavDropdownItemStyled>
                        <NavDropdownItemStyled
                            selected={`${ROUTES.PreinscripcioMatricula}/batxillerat` === location.pathname}
                            onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/batxillerat`)}>
                            Batxillerat
                        </NavDropdownItemStyled>
                        <NavDropdownItemStyled
                            selected={`${ROUTES.PreinscripcioMatricula}/cfgm` === location.pathname}
                            onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/cfgm`)}>
                            CFGM
                        </NavDropdownItemStyled>
                        <NavDropdownItemStyled
                            selected={`${ROUTES.PreinscripcioMatricula}/cfgs` === location.pathname}
                            onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/cfgs`)}>
                            CFGS
                        </NavDropdownItemStyled>
                    </NavDropdownStyled>
                </NavDropdownItemSupply>*/}
            </NavDropdownStyled>
            <NavLinkStyled
                selected={ROUTES.Alumnat === location.pathname}
                onClick={() => handleClick(ROUTES.Alumnat)}>
                Alumnat i famílies
            </NavLinkStyled>
            <NavLinkStyled
                selected={ROUTES.Profesorat === location.pathname}
                onClick={() => handleClick(ROUTES.Profesorat)}>
                Professorat
            </NavLinkStyled>
            <NavLinkStyled
                selected={ROUTES.Projectes === location.pathname}
                onClick={() => handleClick(ROUTES.Projectes)}>
                Projectes
            </NavLinkStyled>
        </Content >
    );
}

export default HeaderRoutes;