import styled from "styled-components";

const LogoWrapper = styled.div`
  display: flex;
  padding: 0;
  border-radius: 3px;
  cursor: pointer;
`;

const Left = styled.div`
  align-self: center;
  padding: 0 6px 0 12px;
`;

const Instituts = styled.div<{ spinner: boolean }>`
  font-size: ${props => props.spinner ? '24px' : '14px'};
  line-height: ${props => props.spinner ? '24px' : '14px'};
  font-weight: bold;
  text-align: right;
  color: #212529;
`;

const Granollers = styled.div<{ spinner: boolean }>`
  font-size: ${props => props.spinner ? '24px' : '14px'};
  line-height: ${props => props.spinner ? '24px' : '14px'};
  color: #c82d2d;
  text-align: right;
  
`;

const Right = styled.div<{ spinner: boolean }>`
  font-size: ${props => props.spinner ? '40px' : '20px'};
  padding: 3px 9px;
  background-color: #c82d2d;
  border-radius: 3px;
  margin: 3px;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  font-weight: bold;
  -webkit-animation: flip-horizontal-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	animation: flip-horizontal-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;

  @-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-360deg);
            transform: rotateX(-360deg);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-360deg);
            transform: rotateX(-360deg);
  }
}
`;

export interface LogoProps {
  modeSpinner?: boolean
}

function Logo(props: LogoProps) {
  return (
    <LogoWrapper>
      <Left>
        <Instituts spinner={!!props.modeSpinner}>institut</Instituts>
        <Granollers spinner={!!props.modeSpinner}>granollers</Granollers>
      </Left>
      <Right spinner={!!props.modeSpinner}>
        EMT
      </Right>
    </LogoWrapper>
  );
}

export default Logo;