import { User, UserCredential, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../config/firebase';

export namespace Auth {

    export const SignIn = (email: string, password: string): Promise<UserCredential> =>
        signInWithEmailAndPassword(auth, email, password);

    export const SignOut = (): Promise<void> =>
        auth.signOut();

    export const SignChange = (): Promise<User | null> => {
        return new Promise((resolve, reject) => {
            auth.onAuthStateChanged(authUser => {
                resolve(authUser);
            }, reject);
        });
    }

    export const SignInEmailAndPassword = (email: string, password: string): Promise<UserCredential> =>
        createUserWithEmailAndPassword(auth, email, password);

}