import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ItinerariEnum } from '../../constants/enums';
import { IPreinscripcio } from '../../types/Preinscripcio';
import { dateFromFirebase, dateToText } from '../../service/date';
import theme from '../../constants/theme';
import { Alert } from 'react-bootstrap';
import { PreinscripcioListApi } from '../../api/preinscripcio-list';

const TimeLineWrapper = styled.div`
    width: 100%;
`;

const Content = styled.div`
    max-width: 700px;
    padding: 12px 0;
`;

const UlStyled = styled.ul`
    list-style: none;
    position: relative;
    text-align: left;
    display: flex; 
    flex-direction: column;
    gap: 24px;
    padding-left: 0;

    &:before {
        content: '';
        position: absolute;
        left: 11px;
        top: 0;
        height: 100%;
        border-left: 3px solid #ccc;
        z-index:1;
    }

    @media(max-width: 600px){
        gap: 12px;
    }
`;

const LiStyled = styled.li<{ active: boolean, width: number }>`
   display: flex; 
   flex-direction: center;
   justify-content: start;
   width: 100%;
   position: relative;


    &:before {
        content: '';
        position: absolute;
        left: 11px;
        top: 0;
        height: ${props => props.width === 100 ? "calc(100% + 24px)" : "50%"};
        border-left: 3px solid ${props => props.active ? "#ccc" : "#C82D2D"};
        z-index: 1;
    }
    
`;

const Title = styled.div`
    font-weight: bold;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Info = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #adb5bd;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Url = styled.div``;

const DateWrapper = styled.div`
    color: #c82d2d;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Link = styled(Alert.Link)`
    color: ${theme.colors.link};

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const PointWrapper = styled.div`
    display: flex;
    flex-direction: center; 
    align-items: center;
`;

const Point = styled.div<{ active: boolean }>`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${props => props.active ? "#FFF" : "#C82D2D"};
    display: block;
    padding: 6px;
    box-sizing: border-box;
    border: 3px solid ${props => props.active ? "#ccc" : "#C82D2D"};;
    z-index: 2;
    position: relative;

    &:before{
        content:'✓';
        position: absolute;
        color: #fff;
        top: -2px;
        left: 2px;
    }
`;

const LiWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 0 12px 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
    padding: 16px;
    border-radius: 8px;

    &.now {
        &:before {
            background-color: #ccc;
        }
    }
`;

const TriangleWrapper = styled.div`
    margin-left: 24px;
    display: flex;
    justify-content: center; 
    align-items: center;
    z-index: 1;

    @media(max-width: 600px){
        margin-left: 8px;
    }
`;

const Triangle = styled.div`
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 20px solid white;
    border-bottom: 15px solid transparent;
`;


export interface TimeLineProps {
    itinerari: ItinerariEnum
}

export function Timeline(props: TimeLineProps) {
    const [preinscripcios, setPreinscripcions] = useState<IPreinscripcio[] | null>([]);

    useEffect(() => {
        if (props.itinerari === ItinerariEnum.default) return;
        PreinscripcioListApi.GetById("list")
            .then((x: any) => {
                let sorted = x.list
                    .filter(x => x.type === props.itinerari)
                    .sort((a, b) => a.order - b.order);
                setPreinscripcions(sorted);
            })
            .catch(x => console.log(x))
    }, [props.itinerari]);

    const renderLink = (text: string, url: string) => {
        return <Link target="_blank" href={url}>{text}</Link>;
    }

    const calcWidth = (index: number) => {
        if (preinscripcios === null) return 0;
        if (preinscripcios?.length === 0) return 0;
        if (preinscripcios.length < index + 1) return 0;
        let next = preinscripcios[index + 1];
        if (!next) return 0;
        let nextDate = dateFromFirebase(next.start);
        if (!nextDate) return 0;
        let now = preinscripcios[index];
        if (!now) return 0;
        let nowDate = dateFromFirebase(now.start);
        if (!nowDate) return 0;
        return nowDate <= new Date() && nextDate <= new Date() ? 100 : 50;
    }

    return (
        <TimeLineWrapper>
            <Content>
                <UlStyled>
                    {preinscripcios?.map((p: IPreinscripcio, index: number) =>
                        <LiStyled
                            key={p.id}
                            active={dateFromFirebase(p.start) >= new Date()}
                            width={calcWidth(index)} >
                            <PointWrapper>
                                <Point active={dateFromFirebase(p.start) >= new Date()} />
                            </PointWrapper>
                            <TriangleWrapper>
                                <Triangle />
                            </TriangleWrapper>
                            <LiWrapper>
                                <DateWrapper>
                                    {dateToText(dateFromFirebase(p.start))}
                                    {p.end && ` - ${dateToText(dateFromFirebase(p.end))}`}
                                </DateWrapper>
                                <Title>{p.title}</Title>
                                <Info>{p.text}</Info>
                                {p.url && <Url>{renderLink(p.urlText ?? "Consulta el document", p.url!)}</Url>}
                            </LiWrapper>
                        </LiStyled>
                    )}
                </UlStyled>
            </Content>
        </TimeLineWrapper>
    );
}

export default Timeline;