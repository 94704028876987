export enum RoleEnum {
    default = 0,
    superadmin = 1,
    secretaria = 2,
    direccio = 3
}

export enum ItinerariEnum {
    default,
    eso,
    batxillerat,
    cfgm,
    cfgs
}