
import styled from "styled-components";

const ChildrenWrapper = styled.div`
  padding-bottom: 64px;
`;

interface WrapperProps {
  children: React.ReactNode;
}

export default function WrapperPage(props: WrapperProps) {
  return (<ChildrenWrapper>{props.children}</ChildrenWrapper>);
}
