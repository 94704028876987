import styled from "styled-components";
import Wrapper from "../wrapper";
import Box from "../box";
import { useContext, useEffect, useState } from "react";
import { StudentsFamilyApi } from "../../api/students-family";
import { IBooks } from "../../types/Books";
import { MainContext } from "../../context/main-provider";


const Content = styled.div``;

const Title = styled.h2`
  display: flex; 
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  font-weight: bold; 
  @media(max-width: 1024px){
    font-size: 28px;
    line-height: 28px;
  }

  @media(max-width: 600px){
    font-size: 22px;
    line-height: 22px;
  }
`;

const Info = styled.div` 
  text-align: center;
  font-size: 18px;
  padding-bottom: 24px;

  @media(max-width: 920px){
    padding-bottom: 21px;
  }

  @media(max-width: 600px){
    font-size: 14px;
    text-align: left;
  }
`;

const List = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
  padding: 0 96px;

  @media (max-width: 900px){
    padding: 0 24px;
  }

  @media (max-width: 600px){
    padding: 0;
    justify-content: start; 
    gap: 8px;
  }
`;

const Item = styled.div`
  padding: 12px;
  background-color: #c82d2d;
  border-radius: 24px;
  color: #fff;
  cursor:pointer;

  @media (max-width: 600px){
    font-size: 14px;
    padding: 6px 12px;
  }
`;


const WrapperBox = styled.div`
  padding: 16px 0;
`;

function Books() {
  const inici = useContext(MainContext);
  const [books, setBooks] = useState<IBooks | null>(null);

  useEffect(() => {
    if (inici === null || !inici.Books) return;
    StudentsFamilyApi.ListBooks()
      .then((x: IBooks) => { setBooks(x) })
      .catch(x => console.log(x));
  }, [inici]);

  const openUrl = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  if (inici === null || !inici.Books) return <></>;

  return (
    <Content>
      <Wrapper>
        <Box backgroundColor="#fff">
          <WrapperBox>
            <Title>Llibres de text</Title>
            <Info>Consulta la llista de llibres de text pel curs {new Date().getFullYear()}/{new Date().getFullYear() + 1}</Info>
            <List>
              <Item onClick={() => openUrl(books?.ESO1!)}>1r ESO</Item>
              <Item onClick={() => openUrl(books?.ESO2!)}>2n ESO</Item>
              <Item onClick={() => openUrl(books?.ESO3!)}>3r ESO</Item>
              <Item onClick={() => openUrl(books?.ESO4!)}>4t ESO</Item>
              <Item onClick={() => openUrl(books?.Batxillerat1!)}>1r Batxillerat</Item>
              <Item onClick={() => openUrl(books?.Batxillerat2!)}>2n Batxillerat</Item>
              <Item onClick={() => openUrl(books?.Administratiu!)}>Administratiu</Item>
              <Item onClick={() => openUrl(books?.Automocio!)}>Automoció</Item>
              <Item onClick={() => openUrl(books?.Electricitat!)}>Electricitat i electrònica</Item>
              <Item onClick={() => openUrl(books?.Mecanica!)}>Mecànica</Item>
              <Item onClick={() => openUrl(books?.Quimica!)}>Química</Item>
              <Item onClick={() => openUrl(books?.Sanitaria!)}>Sanitat</Item>
            </List>
          </WrapperBox>
        </Box>
      </Wrapper>
    </Content >
  );
}

export default Books;