export namespace ROUTES {
    export const NotFound = "*"
    export const Home = '/';
    export const Historia = '/centre/historia';
    export const PoliticaQualitat = '/centre/politica-qualitat';
    export const DocumentacioEstrategica = '/centre/documentacio-estrategica';
    export const OfertaEducativaESO = '/oferta-educativa/eso';
    export const OfertaEducativaBatxillerat = '/oferta-educativa/batxillerat';
    export const OfertaEducativaCFGM = '/oferta-educativa/cfgm';
    export const OfertaEducativaCFGS = '/oferta-educativa/cfgs';
    export const Tramits = '/secretaria/tramits';
    export const TramitsConvalidacioEso = '/secretaria/tramits/convalidacio-musica-eso';
    export const TramitsConvalidacioBatxillerat = '/secretaria/tramits/convalidacio-musica-batxillerat';
    export const TramitsConvalidacioFp = '/secretaria/tramits/convalidacio-cf';
    export const TramitsNivellCatala = '/secretaria/tramits/nivell-catala';
    export const PreinscripcioMatricula = '/secretaria/preinscripcio-i-matricula';
    export const Profesorat = '/profesorat';
    export const Alumnat = '/alumnat-families';
    export const Secretaria = '/secretaria';
    export const Projectes = '/projectes';
    export const Search = '/search';
    export const Admin = '/admin';
    export const PolicyCookies = '/politica-cookies'
    export const PolicyPrivacy = '/politica-privacy';
}