import { arrayRemove, arrayUnion, doc, getDoc, getFirestore, setDoc, updateDoc, } from "firebase/firestore";
import { firestore } from "../config/firebase";

import { IPreinscripcio } from "../types/Preinscripcio";


export namespace PreinscripcioListApi {

    const table = 'preinscripcio-list';
    const document = 'list';
    const db = getFirestore();

    export const CreateAll = async (list: IPreinscripcio[]) => {
        const docRef = doc(db, table, document);
        await setDoc(docRef, { list: list });
    }

    export const Create = async (start: IPreinscripcio) => {
        const docRef = doc(db, table, document);
        await updateDoc(docRef, { list: arrayUnion(start) });
    }

    export const Delete = async (start: IPreinscripcio) => {
        const docRef = doc(db, table, document);
        await updateDoc(docRef, { list: arrayRemove(start) });
    }

    export const Update = async (start: IPreinscripcio) => {
        const docRef = doc(firestore, table, document);
        const result = await getDoc(docRef);
        const data = result.data() as any;
        const found = (data.list as IPreinscripcio[]).find(x => x.id === start.id);
        if (!found) return null;
        found.end = start.end;
        found.order = start.order;
        found.start = start.start;
        found.text = start.text;
        found.title = start.title;
        found.type = start.type;
        found.url = start.url;
        found.urlText = start.urlText;
        await updateDoc(docRef, { list: data.list });
    }

    export const GetById = async (id: string): Promise<IPreinscripcio[]> => {
        const result = await getDoc(doc(firestore, table, id));
        return result.data() as IPreinscripcio[];
    }
}