import styled from "styled-components";
import { useContext } from "react";
import { MainContext } from "../context/main-provider";

const Content = styled.div`
  color: #AE8816;
  background-color: #FFE7A1; 
  padding: 9px 0;
  width: 100%;
  z-index: 10;
  border: 1px solid rgba(73, 80, 87, 0.14);

  @media (max-width: 600px){
    padding: 4px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Notice = styled.div`
  width: calc(100% - 150px);
  text-align: center;
  position: absolute;
  white-space: nowrap;

  @media (max-width: 600px){
    animation: moveLeftRight 10s linear infinite;
    font-size: 14px;
  }

  @keyframes moveLeftRight {
    0% { transform: translateX(100%) }
    100% { transform: translateX(-100%) }
  }
`;


export default function Promo() {
  const inici = useContext(MainContext);
  if (inici === null || !inici.PromoText) return <></>;
  return (
    <Content>
      <Wrapper>
        <Notice>{inici?.PromoText}</Notice>
      </Wrapper>
    </Content>
  );
}
