import styled from "styled-components";
import Wrapper from "../wrapper";
import Box from "../box";
import { Alert } from "react-bootstrap";
import theme from "../../constants/theme";
import { useContext } from "react";
import { MainContext } from "../../context/main-provider";


const Content = styled.div``;

const Title = styled.h2`
  display: flex; 
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  font-weight: bold; 
  @media(max-width: 1024px){
    font-size: 28px;
    line-height: 28px;
  }

  @media(max-width: 600px){
    font-size: 22px;
    line-height: 22px;
  }
`;

const Info = styled.div` 
  text-align: center;
  font-size: 18px;
  padding-bottom: 24px;

  @media(max-width: 920px){
    padding-bottom: 21px;
  }

  @media(max-width: 600px){
    font-size: 14px;
    text-align: left;
  }
`;

const Link = styled(Alert.Link)`
  text-align: center;
  color: ${theme.colors.link};
  display: block;

  @media(max-width: 600px){
      font-size: 14px;
  }
`;

const WrapperBox = styled.div`
  padding: 16px 0;
`;

function PromoAlertInfo() {
  const inici = useContext(MainContext);

  if (inici === null || !inici.PromoAlert) return <></>;

  return (
    <Content>
      <Wrapper>
        <Box backgroundColor="#ffa1a1">
          <WrapperBox>
            <Title>{inici.PromoAlert}</Title>
            <Info>{inici.PromoAlertDescription}</Info>
            {inici?.PromoAlertLink && <Link>{inici.PromoAlertLink}</Link>}
          </WrapperBox>
        </Box>
      </Wrapper>
    </Content >
  );
}

export default PromoAlertInfo;