

interface Theme {
    colors: Colors;
}

interface Colors {
    main: string;
    white: string;
    white100: string;
    white200: string;
    green: string;
    grey: string;
    grey100: string;
    black: string;
    black100: string;
    black200: string;
    link: string;
    shadow: string;
}

const theme: Theme = {
    colors: {
        main: '#c82d2d',
        white: '#fff',
        white100: '#ffffffbc',
        white200: '#f6f6f6',
        green: '#0fa05c',
        grey: '#5d5d5d7d',
        grey100: '#342e2e',
        black: '#000',
        black100: '#0100006b',
        black200: '#49505724',
        link: '#0d6efd',
        shadow: '#9B9B9B54'
    }
};

export default theme;