import { useContext, useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { ItinerariEnum, RoleEnum } from '../../../constants/enums';
import { IPreinscripcio } from '../../../types/Preinscripcio';
import { UserContext } from '../../../context/user-provider';
import theme from '../../../constants/theme';
import { PreinscripcioModalAdd } from './add';
import { ConfigApi } from '../../../api/config';
import { MainContext } from '../../../context/main-provider';
import { PreinscripcioListApi } from '../../../api/preinscripcio-list';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
`;

const EmptyStage = styled.div``;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
    width: fit-content;
`;

const TitleWrapper = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledTh = styled.th<{ width?: number }>`
  width: ${(props) => `${props.width}%` || 'auto'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const StyledTd = styled.td<{ width?: number }>`
  width: ${(props) => `${props.width}%` || 'auto'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTable = styled(Table)`
  width: 100%;
  table-layout: fixed; // Asegura que la tabla use anchos fijos
`;

const Promo = styled.input`
    margin: 8px;
`;

export interface PreinscripcioListProps {
    itinerari: ItinerariEnum;
}

export function PreinscripcioList(props: PreinscripcioListProps) {
    const [preinscripcios, setPreinscripcions] = useState<IPreinscripcio[] | null>([]);
    const inici = useContext(MainContext);
    const [refresh, setRefresh] = useState<boolean>(false);
    const user = useContext(UserContext);
    const [preinscripcio, setPreinscripcio] = useState<IPreinscripcio | null>(null);
    const [nou, setNou] = useState<boolean>(false);
    const [promo, setPromo] = useState<string | null>(null);
    const main = useContext(MainContext);

    useEffect(() => {
        if (props.itinerari === ItinerariEnum.default) return;
        PreinscripcioListApi.GetById("list")
            .then((x: any) => {
                let sorted = x.list
                    .filter(x => x.type === props.itinerari)
                    .sort((a, b) => a.order - b.order);
                setPreinscripcions(sorted);
            })
            .catch(x => console.log(x))
            .finally(() => setRefresh(false));
        // eslint-disable-next-line
    }, [props.itinerari, refresh]);

    useEffect(() => {
        if (!main) return;
        if (props.itinerari === ItinerariEnum.eso) setPromo(main.PreinscripcioIdESO);
        if (props.itinerari === ItinerariEnum.batxillerat) setPromo(main.PreinscripcioIdBatxillerat);
        if (props.itinerari === ItinerariEnum.cfgm) setPromo(main.PreinscripcioIdCFGM);
        if (props.itinerari === ItinerariEnum.cfgs) setPromo(main.PreinscripcioIdCFGS);
    }, [props.itinerari, main]);

    const updateConfig = (id: string | null, start: any, end: any) => {
        if (!inici) return;

        if (props.itinerari === ItinerariEnum.eso) {
            inici.PreinscripcioIdESO = id ?? null;
            inici.PreinscripcioStartESO = start ?? null;
            inici.PreinscripcioEndESO = end ?? null;
        }
        if (props.itinerari === ItinerariEnum.batxillerat) {
            inici.PreinscripcioIdBatxillerat = id ?? null;
            inici.PreinscripcioStartBatxillerat = start ?? null;
            inici.PreinscripcioEndBatxillerat = end ?? null;
        }
        if (props.itinerari === ItinerariEnum.cfgm) {
            inici.PreinscripcioIdCFGM = id ?? null;;
            inici.PreinscripcioStartCFGM = start ?? null;
            inici.PreinscripcioEndCFGM = end ?? null;
        }
        if (props.itinerari === ItinerariEnum.cfgs) {
            inici.PreinscripcioIdCFGS = id ?? null;;
            inici.PreinscripcioStartCFGS = start ?? null;
            inici.PreinscripcioEndCFGS = end ?? null;
        }

        ConfigApi.CreateInici(inici);
    }

    const handlerRemove = (pre: IPreinscripcio) => {
        if (!window.confirm('Estàs segur que vols eliminar el registre?')) return;
        PreinscripcioListApi.Delete(pre)
            .then(x => setRefresh(!refresh))
            .catch(x => console.log(x));
    }

    const handlerClose = () => {
        setNou(false);
        setPreinscripcio(null);
    }

    const handleCreate = () => {
        setRefresh(true);
    }

    const handleNew = () => {
        setNou(true);
        setPreinscripcio({
            id: null,
            type: props.itinerari,
            url: null,
            text: null,
            title: null,
            start: null,
            end: null,
            order: 0
        });
    }

    const handleEdit = (value: IPreinscripcio) => {
        setNou(true);
        setPreinscripcio(value);
    }

    const handleOnClickPromo = (id, start, end) => {
        setPromo(null);
        updateConfig(null, null, null);
    };

    const handleOnChangePromo = (id, start, end) => {
        setPromo(id);
        updateConfig(id, start, end);
    }

    return (
        <Content>
            <TitleWrapper>
                <Title>Llistat {ItinerariEnum[props.itinerari].toUpperCase()}</Title>
                {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <ButtonStyled onClick={handleNew}>
                    Nou
                </ButtonStyled>}
            </TitleWrapper>
            {(!preinscripcios || preinscripcios.length === 0) && <EmptyStage>0 elements trobats</EmptyStage>}
            {preinscripcios && preinscripcios.length > 0 && <StyledTable
                striped
                bordered
                size="100%"
                hover>
                <thead>
                    <tr>
                        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <StyledTh width={10} />}
                        <StyledTh width={10} />
                        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <StyledTh width={10}>Promo</StyledTh>}
                        <StyledTh width={20}>Títol</StyledTh>
                        <StyledTh width={20}>Text</StyledTh>
                        <StyledTh width={5}>Ordre</StyledTh>
                        <StyledTh width={10}>Document GDrive</StyledTh>
                        <StyledTh width={10}>Text GDrive</StyledTh>
                        <StyledTh width={10}>Data inici</StyledTh>
                        <StyledTh width={10}>Data fi</StyledTh>
                    </tr>
                </thead>
                <tbody>
                    {preinscripcios && preinscripcios.length > 0 && preinscripcios.map(x =>
                        <tr key={x.id}>
                            {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
                                <StyledTd width={10}>
                                    <ButtonStyled color="secondary" onClick={() => handlerRemove(x)}>
                                        Esborra
                                    </ButtonStyled>
                                </StyledTd>
                            }
                            <StyledTd width={10}>
                                <ButtonStyled onClick={() => handleEdit(x)}>
                                    Edita
                                </ButtonStyled>
                            </StyledTd>
                            {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
                                <StyledTd width={20}>
                                    <Promo
                                        type="radio"
                                        value={x.id!}
                                        checked={promo === x.id}
                                        onChange={(e) => handleOnChangePromo(x.id, x.start, x.end)}
                                        onClick={(e) => handleOnClickPromo(null, null, null)}
                                    />
                                </StyledTd>
                            }
                            <StyledTd width={20}>{x.title}</StyledTd>
                            <StyledTd width={20}>{x.text}</StyledTd>
                            <StyledTd width={20}>{x.order}</StyledTd>
                            <StyledTd width={20}>{x.url} </StyledTd>
                            <StyledTd width={20}>{x.urlText} </StyledTd>
                            <StyledTd width={10}>{x.start && new Date((x.start! as any).seconds * 1000).toLocaleDateString()}</StyledTd>
                            <StyledTd width={10}>{x.end && new Date((x.end! as any).seconds * 1000).toLocaleDateString()}</StyledTd>
                        </tr>
                    )}
                </tbody>
            </StyledTable>}
            {nou && <PreinscripcioModalAdd
                preinscripcio={preinscripcio}
                onClose={handlerClose}
                onCreate={handleCreate}
                open={nou}
            />}
        </Content>);
}

