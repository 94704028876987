import { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import Box from '../box';
import { Auth } from '../../api/auth';
import theme from '../../constants/theme';

const Content = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    max-width: 400px;
    > div{ > div{width: 500px}}
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 24px;
`;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
`;

const Input = styled(Form.Control)`
    ::placeholder{
        color: #d8d8d8;
    }
`;

function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<boolean>(false);

    const handleLogin = async () => {
        Auth.SignIn(email, password)
            .catch(() => setError(true));
    };

    return (<Content>
        <Box>
            <Title>Obre sessió</Title>
            <Form.Group className="mb-3">
                <Form.Label>Correo electrónic</Form.Label>
                <Input
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={e => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Contrasenya</Form.Label>
                <Input
                    type="password"
                    placeholder="*****"
                    value={password}
                    onChange={e => setPassword(e.target.value)} />
            </Form.Group>
            <Form.Group>
                <ButtonStyled onClick={handleLogin}>Iniciar Sesión</ButtonStyled>
            </Form.Group>
            {error && <Alert variant="danger">Check la contrasenya</Alert>}
        </Box>
    </Content>);
}

export default SignIn;
