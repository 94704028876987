
import moment from 'moment';
import 'moment/locale/ca';
moment.locale('ca');

export function dateFromFirebase(date: any): Date {
    return new Date((date! as any).seconds * 1000);
}

export function dateToText(fecha: Date): string {
    return moment(fecha, 'DD/MM/YYYY').format('D [de] MMMM');
}

export function DateCustomFormat(date: string | null, format: string, defaultMessage: string): string {
    if (!date) return defaultMessage;
    const fecha = new Date(date);

    const formateadorCatalan = new Intl.DateTimeFormat('ca-ES', {
        weekday: 'long',    // nombre completo del día de la semana
        day: 'numeric',     // día del mes
        month: 'long',      // nombre completo del mes
        hour: '2-digit',    // horas en formato de 2 dígitos
        minute: '2-digit',  // minutos en formato de 2 dígitos
    });

    const partesFormateadas = formateadorCatalan.formatToParts(fecha);

    let diaSemana = '', diaMes = '', mes = '', hora = '', minutos = '';

    partesFormateadas.forEach(part => {
        if (part.type === 'weekday') diaSemana = part.value;
        if (part.type === 'day') diaMes = part.value;
        if (part.type === 'month') mes = part.value;
        if (part.type === 'hour') hora = part.value;
        if (part.type === 'minute') minutos = part.value;
    });

    return format
        .replace("#dayWeek", diaSemana)
        .replace("#day", diaMes)
        .replace("#month", mes)
        .replace("#hour", hora)
        .replace("#minuts", minutos)
}
