import styled from "styled-components";
//import SearchWord from "../search-word";
import Wrapper from "../wrapper";

const Content = styled.div`
  > div {
    padding: 30px 30px 0;
  }

  @media (max-width: 600px){
    > div {
      padding: 16px 16px 0;
    }
  }
`;

const ImageCover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  object-fit: cover;
  z-index: 1;
  border-radius: 16px;
  filter: brightness(70%);

  @media (max-width: 600px){
    height: 300px;
  }
`;

const Info = styled.div`
  display: flex; 
  justify-content: center;
  align-items: center; 
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  height: 400px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
  position: relative;

  @media (max-width: 930px){
    padding: 0 32px;
  }

  @media (max-width: 600px){
    height: 300px;
    gap: 12px;
  }
`;

const Text = styled.h1`
  max-width: 700px;
  color: #fff;
  text-align: center;
  z-index: 2;
  
  @media(max-width: 1024px){
    font-size: 32px;
    line-height: 36px;
  }

  @media(max-width: 600px){
    font-size: 24px;
    line-height: 30px;
  }
`;

const Advanced = styled.p`
  max-width: 700px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  padding: 12px 0;
  z-index: 2;

  @media (max-width: 600px){
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;

  }
`;


function HomeImageCover() {
  return (
    <Content>
      <Wrapper>
        <Info>
          <ImageCover src={require('../../assets/img/emt.webp')} alt="institut" />
          <Text>Institut Escola Municipal de Treball de Granollers</Text>
          {/*<SearchWord />*/}
          <Advanced>
            L’Institut Escola Municipal de Treball de Granollers reuneix a uns 1800 alumnes, 140 professors/es, estudis d’ESO, Batxillerat i Formació professional amb un projecte educatiu participatiu i innovador.
          </Advanced>
        </Info>
      </Wrapper>
    </Content>
  );
}

export default HomeImageCover;