
import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ConfigApi } from '../../../api/config';
import { IPreinscripcioConfig } from '../../../types/Config';
import theme from '../../../constants/theme';

const Content = styled.div`
`;

const FormItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const FormWrapper = styled.div`
    display:flex;
    justify-content: center;
    align-items: initial;
    flex-direction: column;
    gap: 9px;
    padding-bottom: 24px;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 14px;
    display: block;
`;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
`;

function Instruccions() {
    const [preinscripcio, setPreinscripcio] = useState<IPreinscripcioConfig | null>(null);

    useEffect(() => {
        ConfigApi.GetById("preinscripcio")
            .then((x: IPreinscripcioConfig) => { setPreinscripcio(x) })
            .catch(x => console.log(x));
    }, []);

    const handleESO = (value: string) => {
        setPreinscripcio(prevState => prevState ? { ...prevState, InstruccionsUrlESO: value } : null)
    }

    const handleClickESO = () => {
        ConfigApi.UpdatePreinscripcio("preinscripcio", { InstruccionsUrlESO: preinscripcio?.InstruccionsUrlESO })
            .catch(x => console.log(x));
    }

    const handleBatxillerat = (value: string) => {
        setPreinscripcio(prevState => prevState ? { ...prevState, InstruccionsUrlBatxillerat: value } : null)
    }

    const handleClickBatxillerat = () => {
        ConfigApi.UpdatePreinscripcio("preinscripcio", { InstruccionsUrlBatxillerat: preinscripcio?.InstruccionsUrlBatxillerat })
            .catch(x => console.log(x));
    }

    const handleCFGM = (value: string) => {
        setPreinscripcio(prevState => prevState ? { ...prevState, InstruccionsUrlCFGM: value } : null);
    }

    const handleClickCFGM = () => {
        ConfigApi.UpdatePreinscripcio("preinscripcio", { InstruccionsUrlCFGM: preinscripcio?.InstruccionsUrlCFGM })
            .catch(x => console.log(x));
    }

    const handleCFGS = (value: string) => {
        setPreinscripcio(prevState => prevState ? { ...prevState, InstruccionsUrlCFGS: value } : null);
    }

    const handleClickCFGS = () => {
        ConfigApi.UpdatePreinscripcio("preinscripcio", { InstruccionsUrlCFGS: preinscripcio?.InstruccionsUrlCFGS })
            .catch(x => console.log(x));
    }

    return (<Content>
        <FormWrapper>
            <FormItemWrapper>
                <Label>Instruccions ESO</Label>
                <Form.Control
                    as="textarea"
                    rows={1}
                    value={preinscripcio?.InstruccionsUrlESO ?? ''}
                    onChange={e => handleESO(e.target.value)} />
                <ButtonStyled onClick={handleClickESO}>Desar</ButtonStyled>
            </FormItemWrapper>
            <FormItemWrapper>
                <Label>Instruccions Batxillerat</Label>
                <Form.Control
                    as="textarea"
                    rows={1}
                    value={preinscripcio?.InstruccionsUrlBatxillerat ?? ''}
                    onChange={e => handleBatxillerat(e.target.value)} />
                <ButtonStyled onClick={handleClickBatxillerat}>Desar</ButtonStyled>
            </FormItemWrapper>
            <FormItemWrapper>
                <Label>Instruccions CFGM</Label>
                <Form.Control
                    as="textarea"
                    rows={1}
                    value={preinscripcio?.InstruccionsUrlCFGM ?? ''}
                    onChange={e => handleCFGM(e.target.value)} />
                <ButtonStyled onClick={handleClickCFGM}>Desar</ButtonStyled>
            </FormItemWrapper>
            <FormItemWrapper>
                <Label>Instruccions CFGS</Label>
                <Form.Control
                    as="textarea"
                    rows={1}
                    value={preinscripcio?.InstruccionsUrlCFGS ?? ''}
                    onChange={e => handleCFGS(e.target.value)} />
                <ButtonStyled onClick={handleClickCFGS}>Desar</ButtonStyled>
            </FormItemWrapper>
        </FormWrapper>


    </Content>);
}

export default Instruccions;
