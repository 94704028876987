
import styled from "styled-components";
import Wrapper from "../../../components/wrapper";
import Box from "../../../components/box";
import theme from "../../../constants/theme";
import { Alert } from "react-bootstrap";
import { Helmet } from 'react-helmet';


const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const Titulo = styled.h1`
    padding-bottom: 24px; 

    @media(max-width: 900px){
        font-size: 24px;
    }
`;

const Subtitle = styled.div`
    font-size: 21px;
    font-weight: bold;
    padding-bottom: 24px;

    @media(max-width: 600px){
        font-size: 18px;
        padding-bottom: 12px;
        line-height: 21px;
    }
`;

const Section = styled.div`
    padding-bottom: 48px;
`;

const DescriptionNoPadding = styled.div``;

const List = styled.ul``;

const Item = styled.li`
    cursor: pointer;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
    @media(max-width: 600px){
        font-size: 14px;
        line-height: 12px;
    }
`;


function TramitsCertificatNivellCatala() {

    const openUrl = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return (
        <Content >
            <Helmet>
                <title>Certificat nivell de català</title>
                <meta name="description" content="Certificat nivell de català" />
            </Helmet>
            <Wrapper>
                <Titulo>Certificat nivell de català</Titulo>
                <Section>
                    <Subtitle>Requisits </Subtitle>
                    <Box>
                        <List>
                            <Item>Haver nascut a partir de l’any 1972 (inici escolarització a partir del curs 1978-1979).</Item>
                            <Item>Escolarització complerta a Catalunya (des d’EGB o Primària)</Item>
                            <Item>No estar exempt de català</Item>
                            <Item>Tenir algun dels següents títols; EGB, ESO, BATXILLERAT, FP1 o FP2.</Item>
                            <Item>El fet de disposar d’un Cicle Formatiu de Grau Mitjà o de Grau Superior no permet acreditar l’obtenció del nivell de català.
                            </Item>
                        </List>
                    </Box>
                </Section>
                <Section>
                    <Subtitle>Com es sol·licita?</Subtitle>
                    <Box>
                        <List>
                            <Item>
                                Complimentar la <Link onClick={() => openUrl('https://drive.google.com/file/d/1pT4Jub_EgvfPrR5NGXxwFZwt6T1iogaN/view?usp=drive_link')}>Sol·licitud del certificat d’equivalència de nivell de català.</Link>
                            </Item>
                            <Item>
                                Presentar la sol·licitud complimentada juntament amb la documentació que s’indica al quadre d’equivalències, d’una de les dues opcions següents

                                <List>
                                    <Item>No estar exempt de català</Item>
                                    <Item>Tenir algun dels següents títols; EGB, ESO, BATXILLERAT, FP1 o FP2.</Item>
                                    <Item>El fet de disposar d’un Cicle Formatiu de Grau Mitjà o de Grau Superior no permet acreditar l’obtenció del nivell de català.
                                    </Item>
                                </List>
                            </Item>
                        </List>
                        <DescriptionNoPadding>
                            <Link onClick={() => openUrl('https://drive.google.com/file/d/179U5T5GAdFpJQHiOZlZJZlRNY7JF46Ch/view?usp=sharing')}>Consulteu el quadre d’equivalències del Nivell B2 i C1 de català.</Link>
                        </DescriptionNoPadding>
                    </Box>
                </Section>
            </Wrapper>
        </Content>
    );
}

export default TramitsCertificatNivellCatala;