
import { useEffect, useState } from "react";
import { Text } from '../types/Text'

const useText = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [texts, setTexts] = useState<Text[]>([]);

    useEffect(() => {
        import('../data/search.json')
            .then((jsonData) => {
                setTexts(jsonData.default);
                setLoaded(true);
            })
            .catch((error) => console.error('Error al cargar el JSON:', error));
    }, []);

    const search = (word: string): Text[] => {
        return texts.filter(item => searchInObject(item, word));
    }

    const searchInObject = (obj: Text, searchText: string) => {
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                if (searchInObject(obj[key], searchText)) {
                    return true;
                }
            } else if (typeof obj[key] === 'string') {
                if (obj[key].toLowerCase().includes(searchText.toLowerCase())) {
                    return true;
                }
            }
        }
        return false;
    };

    return { loaded, search };
};

export default useText;