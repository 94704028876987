import { createContext, useEffect, useState } from 'react';
import { ConfigApi } from '../api/config';
import { IIniciConfig } from '../types/Config';

export const MainContext = createContext<IIniciConfig | null>(null);

export const IniciConfigProvider = ({ children }) => {
    const [inici, setInici] = useState<IIniciConfig | null>(null);

    useEffect(() => {
        ConfigApi.GetById("inici")
            .then((x: IIniciConfig) => setInici(x))
            .catch(x => console.log(x));
    }, []);

    return (
        <MainContext.Provider value={inici}>
            {children}
        </MainContext.Provider>
    );
};