import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";
import { User } from "../types/User";

export namespace UsersApi {

    const table = 'users';

    export const List = async (): Promise<User[]> => {
        const list: User[] = [];
        const result = await getDocs(collection(firestore, table));
        result.forEach((doc) => list.push(doc.data() as User));
        return list;
    }

    export const Create = async (user: User) => {
        return await setDoc(doc(collection(firestore, table), user.id), user);
    }

    export const Delete = async (id: string) => {
        return await deleteDoc(doc(collection(firestore, table), id));
    }

    export const GetById = async (id: string): Promise<User> => {
        const result = await getDoc(doc(firestore, table, id));
        return result.data() as User;
    }
}