
import styled from "styled-components";
import Wrapper from "../components/wrapper";
import { Helmet } from 'react-helmet';

const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const H1 = styled.h1`
    margin-bottom: 24px;
    @media(max-width: 900px){
        font-size: 24px;
    }
`;

const List = styled.ul`
    margin-bottom: 0;
`;

const Item = styled.li`

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Paragraf = styled.div<{ nopadding?: boolean }>`
    padding-bottom: ${props => props.nopadding ? '0' : '24px'};

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Ask = styled.div`
    font-weight: bold;
`;


function PolicyPrivacy() {
    return (
        <Content >
            <Helmet>
                <title>Política de Privacitat</title>
                <meta name="description" content="Política de Privacitat" />
            </Helmet>
            <Wrapper>
                <H1>Política de Privacitat</H1>

                <Paragraf>
                    Benvingut/da al nostre portal web. La teva privacitat és molt important per a nosaltres, i estem compromesos a protegir les teves dades personals. Aquesta Política de Privacitat descriu com recopilem, utilitzem, compartim i protegim les teves dades personals.
                </Paragraf>

                <Ask>Responsable del Tractament</Ask>
                <Paragraf>El responsable del tractament de les dades personals recollides en aquest portal és AAAAAA, amb domicili a AAAAAA i correu electrònic AAAAAA.</Paragraf>

                <Ask>Dades Personals Recopilades</Ask>
                Podem recopilar les següents dades personals quan visites o utilitzes el nostre portal:

                <List>
                    <Item>Informació demogràfica: edat, gènere, país.</Item>
                    <Item>Informació de navegació: tipus de navegador, pàgines visitades, temps de permanència i altres dades relacionades amb la navegació.</Item>
                    <Item>Informació proporcionada per l'usuari: comentaris, ressenyes, o qualsevol altre tipus d'informació que l'usuari decideixi compartir.</Item>
                </List>
                <br />

                <Ask>Finalitats del Tractament</Ask>
                Les dades personals que recopilem es poden utilitzar per a les següents finalitats:

                <List>
                    <Item>Proporcionar i gestionar els serveis oferts a través del nostre portal.</Item>
                    <Item>Respondre a les consultes, sol·licituds o reclamacions fetes per l'usuari.</Item>
                    <Item>Millorar l'experiència de navegació i el funcionament del nostre portal.</Item>
                    <Item>Complir amb les obligacions legals i normatives aplicables.</Item>
                </List>
                <br />

                <Ask> Base Legal per al Tractament</Ask>
                El tractament de les teves dades personals es basa en:

                <List>
                    <Item>El teu consentiment explícit, que pots retirar en qualsevol moment.</Item>
                    <Item>La necessitat de tractar les dades per a l'execució d'un contracte o per adoptar mesures precontractuals.</Item>
                    <Item>El compliment d'una obligació legal aplicable al responsable del tractament.</Item>
                    <Item>L'interès legítim del responsable del tractament en millorar els serveis i l'experiència d'usuari.</Item>
                </List>
                <br />

                <Ask>Conservació de les Dades</Ask>
                <Paragraf>Les teves dades personals es conservaran durant el temps necessari per complir amb les finalitats per a les quals es van recopilar i per determinar les possibles responsabilitats que es puguin derivar de les finalitats, a més dels períodes establerts en la normativa aplicable.</Paragraf>

                <Ask>Drets de l'Usuari</Ask>
                Tens dret a accedir a les teves dades personals, així com a sol·licitar la rectificació de les dades inexactes o, si escau, sol·licitar la seva supressió quan, entre altres motius, les dades ja no siguin necessàries per a les finalitats per les quals van ser recollides.

                <Ask>Seguretat de les Dades</Ask>
                <Paragraf>El responsable del tractament adopta les mesures tècniques i organitzatives necessàries per garantir la seguretat de les dades de caràcter personal i evitar-ne la destrucció, pèrdua, accés o alteració il·lícita.</Paragraf>

                <Ask>Modificacions de la Política de Privacitat</Ask>
                <Paragraf>El responsable del tractament es reserva el dret a modificar aquesta Política de Privacitat per adaptar-la a les novetats legislatives o jurisprudencials, així com a les pràctiques de la indústria. En aquests casos, s'anunciaran els canvis introduïts amb antelació raonable abans de la seva posada en pràctica.</Paragraf>

                <Ask>Contacte</Ask>
                <Paragraf>Si tens qualsevol pregunta o dubte sobre aquesta Política de Privacitat o sobre el tractament de les teves dades personals, pots contactar amb nosaltres a través del correu electrònic oficines@iesemt.net.</Paragraf>

            </Wrapper >
        </Content >
    );
}

export default PolicyPrivacy;