
import styled from 'styled-components';
import { useState } from 'react';
import { UserList } from './list';
import { UserAdd } from './add';

const Content = styled.div``;

export function Users() {
    const [refreshUserList, setRefreshUserList] = useState<boolean>(false);
    const handlerCreateUser = () => setRefreshUserList(!refreshUserList);

    return (<Content>
        <UserAdd onCreate={handlerCreateUser} />
        <UserList refresh={refreshUserList} />
    </Content>);
}

