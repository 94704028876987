
import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import Checkbox from 'react-simple-checkbox';
import { RoleEnum } from '../../../constants/enums';
import { UserContext } from '../../../context/user-provider';
import { ConfigApi } from '../../../api/config';
import { IIniciConfig } from '../../../types/Config';
import theme from '../../../constants/theme';
import { StartSchoolList } from './list';


const Content = styled.div``;

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 16px 24px 0;
    gap: 8px; 
    > div {
        top:0;
    }
    > label {
        margin-bottom: initial;
    }
`;

const FormItemWrapper = styled.div<{ direction: string }>`
    display: flex; 
    align-items: center;
    justify-content: start;
    gap: 8px;
    flex-direction: ${props => props.direction};
`;

const PromoText = styled(Form.Control)`
    width: 400px;
`;

const Label = styled.label`
    font-weight: bold;
    font-size: 14px;
    display: block;
`;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
`;

const StartSchool = styled.div`
    border: 1px solid grey;
    padding: 16px;
    margin: 24px 0;
`;

const PromoAlert = styled.div`
    border: 1px solid grey;
    padding: 16px;
    margin: 24px 0;
    width: 100%;

    div{
        width: 100%;
        textarea{
            width: 100%;
        }
    }
`;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
`;


export function Inici() {
    const user = useContext(UserContext);
    const [inici, setInici] = useState<IIniciConfig | null>(null);

    useEffect(() => {
        ConfigApi.GetById("inici")
            .then((x: IIniciConfig) => { setInici(x) })
            .catch(x => console.log(x));
    }, []);

    const handlePreinscripcioPromo = () => {
        setInici(prevState => prevState ? { ...prevState, Preinscripcio: !prevState.Preinscripcio } : null)
        ConfigApi.UpdatePreinscripcio("inici", { Preinscripcio: !inici?.Preinscripcio })
            .catch(x => console.log(x));
    }

    const handleStartSchool = () => {
        setInici(prevState => prevState ? { ...prevState, StartSchool: !prevState.StartSchool } : null)
        ConfigApi.UpdatePreinscripcio("inici", { StartSchool: !inici?.StartSchool })
            .catch(x => console.log(x));
    }

    const handleBooks = () => {
        setInici(prevState => prevState ? { ...prevState, Books: !prevState.Books } : null)
        ConfigApi.UpdatePreinscripcio("inici", { Books: !inici?.Books })
            .catch(x => console.log(x));
    }

    const handlePromoText = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PromoText: text.length === 0 ? null : text } : null)
    }

    const handlePromoAlert = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PromoAlert: text.length === 0 ? null : text } : null)
    }

    const handlePromoAlertDescription = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PromoAlertDescription: text.length === 0 ? null : text } : null)
    }

    const handlePromoAlertLink = (text: string) => {
        setInici(prevState => prevState ? { ...prevState, PromoAlertLink: text.length === 0 ? null : text } : null)
    }

    const hanlePromoSave = () => {
        ConfigApi.UpdatePreinscripcio("inici", { PromoText: inici?.PromoText })
            .catch(x => console.log(x));
    }

    const hanlePromoAlertSave = () => {
        ConfigApi.UpdatePreinscripcio("inici", {
            PromoAlert: inici?.PromoAlert,
            PromoAlertDescription: inici?.PromoAlertDescription,
            PromoAlertLink: inici?.PromoAlertLink

        })
            .catch(x => console.log(x));
    }

    return (<Content>

        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <CheckboxWrapper>
            <Checkbox
                size={3}
                checked={inici?.Preinscripcio}
                onChange={() => handlePreinscripcioPromo()}>
            </Checkbox>
            <Form.Label>Promo Preinscripció i matrícula</Form.Label>
        </CheckboxWrapper>}
        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <CheckboxWrapper>
            <Checkbox
                size={3}
                checked={inici?.Books}
                onChange={() => handleBooks()}>
            </Checkbox>
            <Form.Label>Llibres de text</Form.Label>
        </CheckboxWrapper>}
        <FormItemWrapper direction="row">
            <Label>Promo</Label>
            <PromoText
                disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                as="textarea"
                rows={1}
                value={inici?.PromoText ?? ''}
                onChange={e => handlePromoText(e.target.value)} />
            <ButtonStyled onClick={hanlePromoSave}>
                Desar Promo
            </ButtonStyled>
        </FormItemWrapper>
        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
            <PromoAlert>
                <Title>Avís important</Title>
                <FormItemWrapper direction="column">
                    <Form.Group>
                        <Label>Títul</Label>
                        <PromoText
                            disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                            as="textarea"
                            rows={1}
                            value={inici?.PromoAlert ?? ''}
                            onChange={e => handlePromoAlert(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Label>Descripció</Label>
                        <PromoText
                            disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                            as="textarea"
                            rows={1}
                            value={inici?.PromoAlertDescription ?? ''}
                            onChange={e => handlePromoAlertDescription(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Label>Link</Label>
                        <PromoText
                            disabled={(user?.role !== RoleEnum.superadmin && user?.role !== RoleEnum.direccio)}
                            as="textarea"
                            rows={1}
                            value={inici?.PromoAlertLink ?? ''}
                            onChange={e => handlePromoAlertLink(e.target.value)} />
                        <ButtonStyled onClick={hanlePromoAlertSave}>
                            Desar
                        </ButtonStyled>
                    </Form.Group>
                </FormItemWrapper>
            </PromoAlert>
        }
        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
            <StartSchool>
                <CheckboxWrapper>
                    <Checkbox
                        size={3}
                        checked={inici?.StartSchool}
                        onChange={() => handleStartSchool()}>
                    </Checkbox>
                    <Form.Label>Inici curs</Form.Label>
                </CheckboxWrapper>
                <StartSchoolList />
            </StartSchool>
        }

    </Content>);
}

