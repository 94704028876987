import styled from "styled-components";
import Wrapper from "../wrapper";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";

const Content = styled.div`
padding-top: 24px;
`;

const ContentWrapper = styled.div`
`;

const Title = styled.h2`
  text-align: center;
  font-weight: bold; 
  color: #343a40;

  @media(max-width: 1024px){
    font-size: 28px;
    line-height: 28px;
  }

  @media(max-width: 600px){
    font-size: 22px;
    line-height: 22px;
  }
`;

/*const Info = styled.div` 
  text-align: center;
  font-size: 18px;
  color: #343a40;
  max-width: 900px;
  margin: 16px auto;

  @media(max-width: 920px){
    padding-bottom: 21px;
  }

  @media(max-width: 600px){
    font-size: 14px;
    padding-bottom: 18px;
  }
`;*/

const Sections = styled.div`
  display: flex;
  gap: 30px;
  padding: 30px 0 0;
  flex-wrap: wrap;

  @media(max-width: 930px){
    flex-direction: column;
  }

  @media(max-width: 600px){
    padding: 0;
    gap: 16px;
  }
`;

const BoxType = styled.div`
  flex: 0 0 calc(25% - 24px);
  cursor: pointer;
`;

const Section = styled.div`
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  padding: 24px;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.07);
  background-color: #fff;

    @media(max-width: 1024px){
      padding: 18px;
    }
`;

const SectionImage = styled.div`
  position: relative;
  height: 200px;

    @media(max-width: 1024px){
      height: 100px;
    }
`;

const ImageCover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
  z-index: 1;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;

    @media(max-width: 1024px){
      height: 100px;
    }
`;

const SectionTitle = styled.h3`
  font-weight: bold;
  text-align: left;

  @media(max-width: 600px){
    font-size: 21px;
  }
`;

const SectionDescription = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 12px;

    @media(max-width: 600px){
      font-size: 14px;
    }
`;

const More = styled.div`
  display: flex;
  justify-content: end;
  color: #c82d2d;
  margin: 0 4px;
  cursor:pointer;
  
  @media(max-width: 600px){
    font-size: 14px;
  }
`;

function Education() {
  const navigate = useNavigate();
  return (
    <Content>
      <Wrapper>
        <ContentWrapper>
          <Title>Oferta Educativa</Title>
          {/*<Info>Non magna tempor sit at donec cursus adipiscing adipiscing ut at pharetra habitant sagittis tincidunt adipiscing gravida at eget eget volutpat vestibulum.</Info>*/}
          <Sections>
            <BoxType onClick={() => navigate(ROUTES.OfertaEducativaESO)}>
              <SectionImage>
                <ImageCover src={require('../../assets/img/eso.png')} alt="eso" />
              </SectionImage>
              <Section>
                <SectionTitle>ESO</SectionTitle>
                <SectionDescription>
                  L'ESO és una etapa d'ensenyament obligatori, estructurada en quatre cursos escolars que pretén la formació bàsica per tal de garantir a tot l'alumnat una bona orientació i preparació per al seu futur
                </SectionDescription>
                <More>veure més...</More>
              </Section>
            </BoxType>
            <BoxType onClick={() => navigate(ROUTES.OfertaEducativaBatxillerat)}>
              <SectionImage>
                <ImageCover src={require('../../assets/img/batxillerat.png')} alt="batxillerat" />
              </SectionImage>
              <Section>
                <SectionTitle>Batxillerat</SectionTitle>
                <SectionDescription>
                  El batxillerat és l'etapa que es cursa normalment entre els 16 i els 18 anys, tot i que és oberta a persones de qualsevol edat, i forma part de l'educació secundària postobligatòria. És un cicle format per dos cursos acadèmics de 30 hores lectives setmanals
                </SectionDescription>
                <More>veure més...</More>
              </Section>
            </BoxType>
            <BoxType onClick={() => navigate(ROUTES.OfertaEducativaCFGM)}>
              <SectionImage>
                <ImageCover src={require('../../assets/img/fp.png')} alt="fp" />
              </SectionImage>
              <Section>
                <SectionTitle>CFGM</SectionTitle>
                <SectionDescription>
                  La formació professional capaci ta per a l'exercici qualificat de diverses professions i proporciona la formació necessària per adquirir en competències professionals i els coneixements propis de cada sector,
                </SectionDescription>
                <More>veure més...</More>
              </Section>
            </BoxType>
            <BoxType onClick={() => navigate(ROUTES.OfertaEducativaCFGS)}>
              <SectionImage>
                <ImageCover src={require('../../assets/img/cfgs.png')} alt="fp" />
              </SectionImage>
              <Section>
                <SectionTitle>CFGS</SectionTitle>
                <SectionDescription>
                  La formació professional capacita per a l'exercici qualificat de diverses professions i proporciona la formació necessària per adquirir en competències professionals i els coneixements propis de cada sector,
                </SectionDescription>
                <More>
                  <More>veure més...</More>
                </More>
              </Section>
            </BoxType>
          </Sections>
        </ContentWrapper>
      </Wrapper>
    </Content>
  );
}

export default Education;