
import styled from "styled-components";
import WrapperPage from "../components/wrapper-page";
import Wrapper from "../components/wrapper";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { useState } from "react";
import theme from "../constants/theme";
import { Alert } from "react-bootstrap";
import { Helmet } from 'react-helmet';

const Content = styled.div`
    padding: 48px 0;

    @media (max-width: 900px){
        padding: 0;
    }
`;

const H1 = styled.h1`
    font-size: 38px;
    font-weight: bold;
    padding-bottom: 24px;

    @media(max-width: 900px){
        font-size: 24px;
        margin-bottom: 0;
    }
`;

const Sections = styled.div`
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 600px){
        gap: 16px;
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: center;
    align-items: start;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
    border-radius: 8px;

    @media (max-width: 1000px){
        flex-direction: column;
    }

    @media (max-width: 600px){
        gap: 0;
        margin: 0;
        border-bottom: initial;
    }
`;

const Title = styled.div`
    font-size: 28px;
    font-weight: bold;

    @media (max-width: 1000px){
        font-size: 21px;
    }
`;

const SubTitle = styled.div`
    font-size: 21px;
    font-weight: bold;
    padding-bottom: 4px;

    @media (max-width: 1000px){
        font-size: 18px;
    }
`;

const Text = styled.div`
    width: calc(100% - 250px);
    display: flex;
    flex-direction: start;
    align-items: start;
    flex-direction: column; 
    gap: 12px;
    background-color: #fff;
    padding: 32px 24px;
    border-radius: 12px;
    height: 100%;

    @media (max-width: 1000px){
        width: 100%;
    }

    @media (max-width: 600px){
        padding: 0 16px 16px;
    }
`;

const Description = styled.div<{ expanded: boolean }>`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: ${props => (props.expanded ? 'none' : '5')};
    line-clamp: ${props => (props.expanded ? 'none' : '5')};
    transition: -webkit-line-clamp 0.3s ease;

    @media (max-width: 600px){
        font-size: 14px;
    }
`;

const ImageWrapper = styled.div`
    width: 250px;
    height: 250px;
    overflow: hidden;
    position: relative;
    padding: 32px 24px;

    @media (max-width: 1000px){
        width: 100%;
        height: auto;
        padding: 24px  24px 0;
        border-radius: 0;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        display: flex;
        justify-content: end;
    }

    @media (max-width: 600px){
        padding: 16px 16px 0;
    }
`;

const Image = styled.img`
    width: 100%; 
    height: auto;
    object-fit: cover;
    filter: sepia(.3);
    cursor:pointer;

    @media (max-width: 1000px){
        border-radius: initial;
        max-width: 150px;
    }
`;

const List = styled.ul`
    @media (max-width: 600px){
        padding-left: 24px;
    }
`;

const Item = styled.li`
    line-height: 30px;

    @media (max-width: 600px){
        line-height: 21px;
        font-size: 14px;
        line-height: 24px;
    }
`;

const Separator = styled.div`
    padding-bottom: 24px;
`;

const Expand = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: start;    
`;

const DescriptionWrapper = styled.div`
    display: flex;
`;

export const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
`;


function Projects() {
    const [isExpanded, setIsExpanded] = useState<number>(0);

    const toggleExpand = (event: any, id: number) => {
        event.stopPropagation();
        if (id === isExpanded) setIsExpanded(0);
        if (id !== isExpanded) setIsExpanded(id);
    }


    return (
        <Content>
            <Helmet>
                <title>Projectes</title>
                <meta name="description" content="Projectes" />
            </Helmet>
            <WrapperPage>
                <Wrapper>
                    <H1>Projectes</H1>
                    <Sections>
                        <Section>
                            <ImageWrapper>
                                <Image src="https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/erasmusplus_logo_blu.jpg?alt=media&token=b8c9b34f-6a2c-4579-ac33-874b01eb24db" />
                            </ImageWrapper>
                            <Text>
                                <Title>Erasmus+</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 1}>
                                        <SubTitle>Clàusula d’exempció de responsabilitat</SubTitle>
                                        Els projectes presentats en la pàgina web són cofinançats pel programa Erasmus+, Ministerio de Educación y Formación Profesional i altres de la Unión Europea o altres formes de finançament.
                                        El contingut és responsabilitat del centre educatiu i ni la Comissió Europea ni el Servei Espanyol per la Internacionalització de l'Educació (SEPIE) són responsables de l'ús que pugui fer-se de la informació difosa.
                                        <Separator />

                                        <SubTitle> Coordinadores de mobilitat internacional</SubTitle>
                                        <List>
                                            <Item>Mercè Molins (FP: CFGM i CFGS): internationalprojects@iesemt.net</Item>
                                            <Item>Mònica Fernàndez ( ESO i Batxillerat): escolarprojects@iesemt.net</Item>
                                        </List>
                                        <Separator />

                                        <SubTitle>Objectius</SubTitle>
                                        L'Institut Escola Municipal de Treball ha participat en els últims anys en diferents projectes d'àmbit europeu amb estudiants de secundària i de formació professional, com per exemple Erasmus, Leonardo da Vinci, Comenius i E-twinning. El nostre compromís actual és ampliar els projectes de mobilitat internacional dels cicles formatius, de secundària i de batxillerat per tal d'ajudar-los a adquirir noves competències i habilitats lingüístiques, millorar l'autonomia, i en el cas dels cicles, també fomentar la competitivitat i ocupabilitat dels alumnes dins del mercat laboral. La participació en el programa Erasmus+ permet la internacionalització i modernització del nostre centre així com afavoreix l'intercanvi d'aptituds professionals i d'experiències metodològiques entre el nostre centre i les diverses escoles i empreses col·laboradores.
                                        <Separator />


                                        L'estada a l'estranger té com a objectiu principal oferir experiència als alumnes tant a nivell professional com a nivell personal. Per una banda, es contribueix a fer que l'alumne s'adapti a les exigències del mercat laboral a escala comunitària, adquirint aptituds específiques, així com millorar la seva comprensió de l'entorn econòmic i social del país en qüestió, al mateix temps que obté experiència laboral. Ajudant l'alumne a adquirir noves habilitats, coneixements i qualificacions, el programa també persegueix que els estudiants siguin més competitius al mercat laboral europeu. S'amplia així la mobilitat laboral necessària dins del camp professional i les adaptacions als canvis en les qualificacions.
                                        Per altra banda, no només s'aconsegueix la competència lingüística professional en llengua estrangera, sinó que també s'adquireix una identitat i maduresa professional motivadora de futurs aprenentatges.
                                        <Separator />

                                        <SubTitle>Requisits de participació</SubTitle>
                                        <Item>Consulta els <Link onClick={() => window.open('https://drive.google.com/file/d/1AkCQ7DCtMFUPJI4L9zXxuBKde77yUDnT/view?usp=sharing')}>requisits de participació de l’alumnat d’ESO i Batxillerat.</Link></Item>
                                        <Item>Consulta els <Link onClick={() => window.open('https://drive.google.com/file/d/1E9qODR5uQBkbEXg3LCvmNocM6DEExzZ6/view?usp=sharing')}>requisits de participació de l’alumnat d’FP.</Link></Item>
                                        <Separator />

                                        <SubTitle>Altres informacions d’interès</SubTitle>
                                        <Item>Consulta la <Link onClick={() => window.open('https://drive.google.com/file/d/1C38OT1CWhzpxsdSNaJHmyACjDxa9Hp_T/view?usp=drive_link')} >Carta ECHE 2021-27 (Erasmus Charter for Higher Education)</Link></Item>
                                        <Item>Consulta <Link onClick={() => window.open('https://drive.google.com/file/d/1ukSQllNL-CQisy0M-lkF4u3Z6-p_iYs6/view?usp=sharing')}>l’Erasmus Policy Statement</Link></Item>
                                        <Item>Consulta <Link onClick={() => window.open('')}>l’Acreditació VET</Link></Item>
                                        <Separator />

                                        Per a més informació, consulteu la <Link onClick={() => window.open('http://sepie.es/')}>web de SEPIE</Link>
                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 1)}>
                                        {isExpanded === 1 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                        <Section>
                            <ImageWrapper>
                                <Image src="https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/Logo%20Activa.png?alt=media&token=058c13c1-15c1-44fd-9b53-c3014c9a1140" />
                            </ImageWrapper>
                            <Text>
                                <Title>Activa</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 2}>
                                        EMT participa en la Xarxa Activa, des de l'any 2019. És un programa de la Direcció General de Formació Professional i Ensenyaments de Règim Especial, que impulsa contextualitzar els currículums de formació professional a través de l'aprenentatge per projectes, impulsant metodologies actives.
                                        Aquest programa ens permet dotar d'eines als nostres alumnes perquè a més d'assolir les competències professionals del cicle que estan estudiant, també esdevinguin persones responsables, autònomes, amb iniciativa, autocrítiques, amb capacitat de treballar en equip, d'organització del treball i que resolguin problemes. Per aconseguir-ho, utilitzem metodologies d'aprenentatge basades en reptes, projectes, objectius o situacions similars o properes a la realitat laboral:
                                        <List>
                                            <Item>reptes plantejats per l'equip docent</Item>
                                            <Item>reptes plantejats per empreses</Item>
                                            <Item>transferència de coneixement per respondre a necessitats concretes fixades per les empreses que aporten solucions innovadores</Item>
                                            <Item>reptes que promoguin la creació d'empreses entre l'alumnat</Item>
                                        </List>
                                        <Separator />
                                        Responsable: Àlex Ramos
                                        <Separator />
                                        Podeu ampliar informació a la <Link onClick={() => window.open('https://projectes.xtec.cat/impulsfp/activa-fp/')}>web d'activaFP</Link>

                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 2)}>
                                        {isExpanded === 2 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                        <Section>
                            <ImageWrapper>
                                <Image src="https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/Logo%20EmprenFP.png?alt=media&token=3f485ecd-da70-4cac-a4ed-cbb9d0cb0804" />
                            </ImageWrapper>
                            <Text>
                                <Title>Emprèn</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 3}>
                                        L'EMT participa en la Xarxa Emprèn des de l'any 2019. És un programa de la Direcció General de Formació Professional i Ensenyaments de Règim Especial, que dona suport al foment de l'emprenedoria i els seus valors en els centres educatius d'FP d'arreu de Catalunya, per la creació de projectes emprenedors.
                                        L'EMT participa en aquest programa amb l'objectiu de crear un ambient que promogui actituds d'emprenedoria en la comunitat educativa i capacitin l'alumnat per a la creació d'empreses i l'autoocupació (innovació, creativitat, autonomia personal, iniciativa, detecció d'oportunitats i captació de recursos).

                                        <Separator />
                                        Responsable: Jordi Cervantes
                                        <Separator />
                                        Podeu ampliar informació a la <Link onClick={() => window.open('https://projectes.xtec.cat/impulsfp/emprenfp-2/')}>web d'emprenFP</Link>

                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 3)}>
                                        {isExpanded === 3 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                        <Section>
                            <ImageWrapper>
                                <Image src="    https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/Logo%20Innovafp.png?alt=media&token=65abfb77-f8ad-478b-a8fd-9f03461b3c0a" />
                            </ImageWrapper>
                            <Text>
                                <Title>Innova</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 4}>
                                        L'EMT participa en la Xarxa InnovaFP des de l'any 2017. És un programa de la Direcció General de Formació Professional i Ensenyaments de Règim Especial del Departament d'Ensenyament de la Generalitat de Catalunya que promou la col·laboració d'empreses i centres de formació professional.
                                        Aquest programa fomenta la realització de projectes d'innovació o transferència de coneixement entre l'EMT i les empreses de l'entorn, permet mantenir actualitzats els coneixements del professorat, contribueix a augmentar la competitivitat de les empreses i d'altres organitzacions, però sobretot ens ajuda a millorar la capacitació de l'alumnat dels cicles formatius.

                                        <Separator />
                                        Responsable: Edu Gras
                                        <Separator />
                                        Podeu ampliar informació a la <Link onClick={() => window.open('https://projectes.xtec.cat/impulsfp/innovafp/')}>web d'innovaFP</Link>

                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 4)}>
                                        {isExpanded === 4 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                        <Section>
                            <ImageWrapper>
                                <Image src="https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/Logo%20OrientaFP.png?alt=media&token=1ad00a58-abbd-40e3-a7c4-924b3c9a3258" />
                            </ImageWrapper>
                            <Text>
                                <Title>Orienta</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 5}>
                                        L'EMT participa en la Xarxa OrientaFP des de l'any 2020. Treballem per millorar l'acompanyament i l'orientació de l'alumnat en les transicions educatives i professionals, per aconseguir-ho utilitzem eines i potencialitats per a la vida activa.
                                        Durant l'estada al centre educatiu es realitza una avaluació diagnòstica a l'alumnat, l'ajudarem en l'elaboració del seu informe personal d'orientació professional, serà la guia que li ha de permetre definir els seus interessos professionals, conèixer les seves capacitats transversals, definir les empreses en què vol treballar, l'itinerari educatiu que haurà de seguir per aconseguir l'especialització desitjada i sobretot fer promoció personal a través d'activitats de networking professional, estratègies de recerca de feina, currículum per competències i altres eines de presentació.

                                        <Separator />
                                        Responsable: Pol Millan
                                        <Separator />
                                        Podeu ampliar informació a la <Link onClick={() => window.open('https://projectes.xtec.cat/impulsfp/orientafp-2/')}>web d'OrientaFP</Link>

                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 5)}>
                                        {isExpanded === 5 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                        <Section>
                            <ImageWrapper>
                                <Image src="https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/Logo%20EmpresaFP.png?alt=media&token=4794c8f8-ef22-413a-9e15-646b982d608d" />
                            </ImageWrapper>
                            <Text>
                                <Title>Empresa</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 6}>
                                        L'EMT participa en la Xarxa EmpresaFP des de l'any 2020. Desplega estratègies adreçades a fomentar la prestació d'un conjunt de serveis complementaris, de la formació professional, a les empreses i entitats. Alguns dels serveis es descriuen a continuació:
                                        <List>
                                            <Item>Difusió de l'FP a les empreses</Item>
                                            <Item>Actualització de la formació dels treballadors de les empreses</Item>
                                            <Item>Actualització de la formació dels professors a les empreses</Item>
                                            <Item>Borsa de treball</Item>
                                            <Item>Borsa de pràctiques</Item>
                                            <Item>Servei d'assessorament per a l'avaluació i el reconeixement acadèmic</Item>
                                            <Item>Assessorar el col·lectiu d'empreses i entitats sobre els serveis de formació professional</Item>
                                            <Item>Afavorir les vies de cooperació entre centres educatius, empreses i entitats</Item>
                                            <Item>Impulsar els projectes compartits entre centres educatius i empreses</Item>
                                        </List>

                                        <Separator />
                                        Responsable: Ruben Garcia
                                        <Separator />
                                        Podeu ampliar informació a la <Link onClick={() => window.open('https://projectes.xtec.cat/empresafp/')}>web d'EmpresaFP</Link>

                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 6)}>
                                        {isExpanded === 6 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                        <Section>
                            <ImageWrapper>
                                <Image src="https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/Logo%20FuturaFP.png?alt=media&token=3607ab63-e336-4f35-bb69-b39b7abf6c17" />
                            </ImageWrapper>
                            <Text>
                                <Title>FuturaFP</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 7}>
                                        Aquest programa impulsa la recerca i desenvolupament a través de la identificació, implementació, millora contínua i difusió de la innovació educativa, per assolir les expectatives d'una FP de qualitat en el present i el futur.
                                        L'EMT participa en aquest programa que es desenvolupa mitjançant xarxes organitzades per famílies professionals de centres de tot Catalunya i empreses, amb l'objectiu de crear contextos de reflexió, detectar i definir nous escenaris de futur, definir àmbits de recerca i innovació, identificar equipaments idonis, elaborar i difondre propostes didàctiques, establir sistema de cooperació, intercanviar coneixements, bones pràctiques i recursos, tot avançant cap a la modernització i excel·lència professional.

                                        <Separator />
                                        Responsable: Un responsable de cada cicle formatiu
                                        <Separator />
                                        Podeu ampliar informació a la <Link onClick={() => window.open('https://projectes.xtec.cat/empresafp/')}>web d'FuturaFP</Link>

                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 7)}>
                                        {isExpanded === 7 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                        <Section>
                            <ImageWrapper>
                                <Image src="https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/Logo%20Catskills.png?alt=media&token=742e8d72-6688-4464-b07a-e124f3e029be" />
                            </ImageWrapper>
                            <Text>
                                <Title>Catskills</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 8}>
                                        Catskills són els Campionats d'FP de Catalunya, on el Departament d'Educació de la Generalitat de Catalunya organitza i promou competicions de destreses (skills, en anglès). Constitueixen un valuós instrument divulgatiu de la Formació Professional i un mitjà per estimular a estudiants, professorat i empreses, a més d'una plataforma d'intercanvi i un fòrum de debat sobre l'evolució dels estàndards professionals, la qualitat i la innovació en els diferents sectors productius.


                                        <Separator />
                                        No té responsable
                                        <Separator />
                                        Podeu ampliar informació a la <Link onClick={() => window.open('https://projectes.xtec.cat/empresafp/')}>web Catskills</Link>

                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 8)}>
                                        {isExpanded === 8 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                    </Sections>
                </Wrapper>
            </WrapperPage>
        </Content>
    );
}

export default Projects;