import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import { ItinerariEnum, RoleEnum } from '../../../constants/enums';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { v4 as uuidv4 } from 'uuid';
import { IPreinscripcio } from '../../../types/Preinscripcio';
import { UserContext } from '../../../context/user-provider';
import { PreinscripcioListApi } from '../../../api/preinscripcio-list';

const Content = styled.div`
    padding: 24px;
`;

const FormWrapper = styled.div`
    display:flex;
    justify-content: center;
    align-items: initial;
    flex-direction: column;
    gap: 9px;
    padding-bottom: 24px;
`;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
`;

const DatePickerStyled = styled(DatePicker)`
    padding: .375rem .75rem;
    border: 1px solid lightgrey;
    border-radius: .375rem;
    width: 100%;

    > input{
        width: 100%;
    }
`;

const Label = styled.label`
    font-weight: bold;
    font-size: 14px;
    display: block;
`;

const FormItemWrapper = styled.div``;

export interface PreinscripcioAddProps {
    onCreate: () => void;
    onClose: () => void;
    open: boolean;
    preinscripcio: IPreinscripcio | null;
}


export function PreinscripcioModalAdd(props: PreinscripcioAddProps) {
    const [order, setOrder] = useState<number>(props.preinscripcio?.order ?? 0);
    const [url, setUrl] = useState<string | undefined | null>(props.preinscripcio?.url);
    const [urlText, setUrlText] = useState<string | undefined | null>(props.preinscripcio?.urlText);
    const [text, setText] = useState<string | undefined | null>(props.preinscripcio?.text);
    const [title, setTitle] = useState<string | undefined | null>(props.preinscripcio?.title);
    const [start, setStart] = useState<Date | null | undefined>(props.preinscripcio?.start && new Date((props.preinscripcio?.start as any).seconds * 1000));
    const [end, setEnd] = useState<Date | null | undefined>(props.preinscripcio?.end && new Date((props.preinscripcio?.end as any).seconds * 1000));
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const user = useContext(UserContext);

    useEffect(() => {
        if (!success) return;
        let timer = setTimeout(() => setSuccess(false), 2000);
        return () => { clearTimeout(timer); };
    }, [success]);

    useEffect(() => {
        if (!error) return;
        let timer = setTimeout(() => setError(false), 2000);
        return () => { clearTimeout(timer); };
    }, [error]);

    const handleSave = async () => {
        if (!start || !title) {
            alert('Obligatori títol i data inici');
            return;
        }

        const pre: IPreinscripcio = {
            id: props.preinscripcio?.id ?? uuidv4(),
            type: props.preinscripcio?.type ?? ItinerariEnum.eso,
            url: url ?? '',
            urlText: urlText ?? '',
            text: text ?? '',
            title: title ?? '',
            start: start,
            end: end ?? null,
            order: order
        };


        if (props.preinscripcio?.id !== null) {
            PreinscripcioListApi.Update(pre)
                .then((cred: any) => {
                    setSuccess(true);
                    props.onCreate()
                })
                .catch((error) => {
                    console.log(error);
                    setError(error);
                });
            return;
        }

        PreinscripcioListApi.Create(pre)
            .then((cred: any) => {
                setSuccess(true);
                props.onCreate()
            })
            .catch((error) => {
                console.log(error);
                setError(error);
            });
    };

    return (
        <Modal show={props.open} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Nou</Modal.Title>
            </Modal.Header>
            <Content>
                <FormWrapper>
                    <FormItemWrapper>
                        <Label>Títol*</Label>
                        <Form.Control
                            disabled={user?.role === RoleEnum.secretaria}
                            as="textarea"
                            rows={2}
                            value={title ?? ''}
                            onChange={e => setTitle(e.target.value)} />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Text</Label>
                        <Form.Control
                            disabled={user?.role === RoleEnum.secretaria}
                            as="textarea"
                            rows={2}
                            value={text ?? ''}
                            onChange={e => setText(e.target.value)} />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Ordre</Label>
                        <Form.Control
                            type="number"
                            disabled={user?.role === RoleEnum.secretaria}
                            value={order ?? 0}
                            onChange={e => setOrder(parseInt(e.target.value))} />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Google drive Text</Label>
                        <Form.Control
                            as="textarea"
                            rows={1}
                            value={urlText ?? ''}
                            onChange={e => setUrlText(e.target.value)} />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Google drive URL</Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            value={url ?? ''}
                            onChange={e => setUrl(e.target.value)} />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Data inici*</Label>
                        <DatePickerStyled
                            disabled={user?.role === RoleEnum.secretaria}
                            width={100}
                            selected={start}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setStart(date)}
                        />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Data fi</Label>
                        <DatePickerStyled
                            disabled={user?.role === RoleEnum.secretaria}
                            selected={end}
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => setEnd(date)}
                        />
                    </FormItemWrapper>
                </FormWrapper>
                <Modal.Footer>
                    <ButtonStyled onClick={handleSave}>
                        Desar
                    </ButtonStyled>
                </Modal.Footer>
                {error && <Alert variant="danger">Error al desar el registre</Alert>}
                {success && <Alert variant="success">S’ha modificat correctament el registre</Alert>}
            </Content>
        </Modal >
    );
}

