
import styled from "styled-components";


export const ChildrenWrapper = styled.div`
  max-width: 1244px;;
  margin: 0 auto;
  padding: 30px 30px 0;
  width: 100%;
  @media (max-width: 768px) { padding: 24px; }
  @media (max-width: 600px) { padding: 16px; }
  @media (max-width: 375px) { padding: 14px }
`;

export interface WrapperProps {
  children: React.ReactNode;
}

export default function Wrapper(props: WrapperProps) {
  return (<ChildrenWrapper>{props.children}</ChildrenWrapper>);
}
