
import styled from "styled-components";
import Wrapper from "../../../components/wrapper";
import { HiDownload } from "react-icons/hi";
import Box from "../../../components/box";
import { Helmet } from 'react-helmet';


const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const Info = styled.div`
    padding: 24px 0;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;


const Titulo = styled.h1`
    @media(max-width: 900px){
        font-size: 24px;
    }
`;

const Subtitle = styled.div`
    font-size: 21px;
    font-weight: bold;
    padding-bottom: 24px;

    @media(max-width: 600px){
        font-size: 18px;
        padding-bottom: 12px;
        line-height: 21px;
    }
`;

const Description = styled.div`
    padding-bottom: 24px;

    @media(max-width: 600px){
        font-size: 14px;
        padding-bottom: 12px;
    }
`;

const Section = styled.div`
    padding-bottom: 48px;
`;

const DescriptionNoPadding = styled.div``;

const List = styled.ul``;

const Item = styled.li`
    cursor: pointer;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;


function TramitsConvalidacioBatxillerat() {

    const openUrl = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return (
        <Content >
            <Helmet>
                <title>Convalidacions música Batxillerat</title>
                <meta name="description" content="Convalidacions música Batxillerat" />
            </Helmet>
            <Wrapper>
                <Titulo>Convalidacions música Batxillerat</Titulo>

                <Info>
                    La sol·licitud es pot presentar <b>fins al darrer dia hàbil de setembre</b> juntament amb tota la documentació necessària.
                    <b> Molt important! L’alumne/a haurà de continuar assistint a classe fins que se li faci entrega de la resolució de la convalidació per part de la direcció del centre. </b>
                    Les matèries que s’hagin convalidat no tenen qualificació ni computen a l’efecte de càlcul de la nota mitjana.
                </Info>
                <Section>
                    <Subtitle>Alumnes de batxillerat que cursen estudis de música en un conservatori o en un centre professional</Subtitle>
                    <Box>
                        <Description>
                            Les matèries que es poden convalidar són les de la franja d’optatives (fins a un màxim de 6 hores setmanals) a primer de batxillerat i les de la franja d’específiques (fins a un màxim de 4 hores setmanals) a segon de batxillerat.
                        </Description>
                        <Description>
                            <b>Documentació que cal presentar en cas de conservatori o centre professional</b>
                        </Description>
                        <List>
                            <Item onClick={() => openUrl('https://drive.google.com/file/d/10m0IJzWU3mBV1p9FbHTSMebayVKq7Xxq/view?usp=sharing')}>Sol·licitud de reducció de matèries de la franja d’específiques de batxillerat basant-se en estudis de música en un conservatori o en un centre professional (model BMU1)<HiDownload color='#343a40' size="1em" /></Item>
                            <Item onClick={() => openUrl('https://drive.google.com/file/d/18JPI8Bwe2YbtxsL-YPmIOhixF5kTpw-_/view?usp=sharing')}>Autorització sortida del centre i responsabilitat del pare/mare/tutor de les hores que el/la seu/va fill/a no assisteix al centre per la convalidació de música.<HiDownload color='#343a40' size="1em" /></Item>
                            <Item>Certificat expedit pel conservatori o pel centre professional de música en què s’acredita el curs que fa l’alumne/a.</Item>
                        </List>
                        <DescriptionNoPadding>
                            No són convalidables les matèries de Religió, ni el Treball de Síntesi (de primer a tercer), ni Educació en Valors Cívis i Ètics, ni el Projecte de Recerca de quart curs.
                        </DescriptionNoPadding>
                    </Box>
                </Section>
                <Section>
                    <Subtitle>Alumnes de batxillerat que cursen estudis de música en una escola de música autoritzada</Subtitle>
                    <Box>
                        <Description>
                            <b>Requisits</b>
                        </Description>
                        <List>
                            <Item>Cursar, com a mínim, 4 hores setmanals o més.</Item>
                            <Item>Cursar estudis en una escola de música autoritzada.</Item>
                            <Item>Haver superat la prova d’accés als ensenyaments de grau professional de música.</Item>
                            <Item>Cursar estudis que incloguin, com a mínim, Llenguatge Musical o Harmonia, Instrument i Pràctica Instrumental en Grup.</Item>
                        </List>
                        <DescriptionNoPadding>
                            No són convalidables les matèries de Religió, ni el Treball de Síntesi (de primer a tercer), ni Educació en Valors Cívis i Ètics, ni el Projecte de Recerca de quart curs.
                        </DescriptionNoPadding>
                        <br />
                        <Description>
                            <b> Documentació que cal presentar en cas d’escola de música autoritzada</b>
                        </Description>
                        <List>
                            <Item onClick={() => openUrl('https://drive.google.com/file/d/1A2bVBLKPf3R39bJELtpy0SXwtXseSMDH/view?usp=sharing')}>Sol·licitud de reducció de matèries de batxillerat basant-se en estudis de música en una escola de música autoritzada  (model BMU3). <HiDownload color='#343a40' size="1em" /></Item>
                            <Item onClick={() => openUrl('https://drive.google.com/file/d/18JPI8Bwe2YbtxsL-YPmIOhixF5kTpw-_/view?usp=sharing')}>Autorització sortida del centre i responsabilitat del pare/mare/tutor de les hores que el/la seu/va fill/a no assisteix al centre per la convalidació de música. <HiDownload color='#343a40' size="1em" /></Item>
                            <Item>Certificat expedit per l’escola de música autoritzada per tal que l’alumne/a pugui acollir-se al reconeixement amb reducció de matèries de batxillerat de la franja d’específiques</Item>
                        </List>
                    </Box>
                </Section>
            </Wrapper>
        </Content>
    );
}

export default TramitsConvalidacioBatxillerat;