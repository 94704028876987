
import styled from "styled-components";
import theme from "../constants/theme";

export const Content = styled.div<{ backgroundColor: string | undefined }>`
  width: 100%;
  display:flex;
  box-shadow: ${theme.colors.shadow} 5px 6px 10px 0px;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : theme.colors.white};
  border-radius: 12px;
`;

export const ChildrenWrapper = styled.div`
  padding: 30px;
  width: 100%; 
  @media (max-width: 768px) { padding: 24px; }
  @media (max-width: 600px) { padding: 16px; }
  @media (max-width: 375px) { padding: 14px }
`;

export interface WrapperProps {
  children: React.ReactNode;
  backgroundColor?: string;
}

export default function Box(props: WrapperProps) {
  return (
    <Content backgroundColor={props.backgroundColor}>
      <ChildrenWrapper>{props.children}</ChildrenWrapper>
    </Content>
  );
}
