import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";
import { IIniciConfig, IPreinscripcioConfig } from "../types/Config";


export namespace ConfigApi {

    const table = 'config';

    export const CreatePreinscripcio = async (preinscripcio: IPreinscripcioConfig) => {
        return await setDoc(doc(collection(firestore, table), "preinscripcio"), preinscripcio);
    }

    export const UpdatePreinscripcio = async (id: string, data: any) => {
        const docRef = doc(collection(firestore, table), id);
        await updateDoc(docRef, data);
    };

    export const CreateInici = async (inici: IIniciConfig) => {
        return await setDoc(doc(collection(firestore, table), "inici"), inici);
    }

    export const GetById = async (id: string): Promise<any> => {
        const result = await getDoc(doc(firestore, table, id));
        return result.data() as any;
    }
}