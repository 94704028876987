
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { RoleEnum } from '../../../constants/enums';
import { UserContext } from '../../../context/user-provider';
import { ConfigApi } from '../../../api/config';
import { IPreinscripcioConfig } from '../../../types/Config';

const Content = styled.div`
`;

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 16px 24px 0;
    gap: 8px; 
    width: fit-content;
    > div {
        top:0;
    }
    > label {
        margin-bottom: initial;
    }
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: row;
`;

const Checkbox1 = styled.input``;

function Checks() {
    const user = useContext(UserContext);
    const [preinscripcio, setPreinscripcio] = useState<IPreinscripcioConfig | null>(null);

    useEffect(() => {
        ConfigApi.GetById("preinscripcio")
            .then((x: IPreinscripcioConfig) => { setPreinscripcio(x) })
            .catch(x => console.log(x));
    }, []);

    const handleESO = () => {
        setPreinscripcio(prevState => prevState ? { ...prevState, ESO: !prevState.ESO } : null)
        ConfigApi.UpdatePreinscripcio("preinscripcio", { ESO: !preinscripcio?.ESO })
            .catch(x => console.log(x));
    }

    const handleBatxillerat = () => {
        setPreinscripcio(prevState => prevState ? { ...prevState, Batxillerat: !prevState.Batxillerat } : null)
        ConfigApi.UpdatePreinscripcio("preinscripcio", { Batxillerat: !preinscripcio?.Batxillerat })
            .catch(x => console.log(x));
    }

    const handleCFGM = () => {
        setPreinscripcio(prevState => prevState ? { ...prevState, CFGM: !prevState.CFGM } : null)
        ConfigApi.UpdatePreinscripcio("preinscripcio", { CFGM: !preinscripcio?.CFGM })
            .catch(x => console.log(x));
    }

    const handleCFGS = () => {
        setPreinscripcio(prevState => prevState ? { ...prevState, CFGS: !prevState.CFGS } : null)
        ConfigApi.UpdatePreinscripcio("preinscripcio", { CFGS: !preinscripcio?.CFGS })
            .catch(x => console.log(x));
    }

    return (<Content>

        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) && <Wrap>
            <CheckboxWrapper>
                <Checkbox1
                    type="checkbox"
                    size={3}
                    checked={preinscripcio?.ESO}
                    onChange={() => { handleESO() }}>
                </Checkbox1>
                <Form.Label > ESO </Form.Label>
            </CheckboxWrapper>
            <CheckboxWrapper >
                <Checkbox1
                    type="checkbox"
                    size={3}
                    checked={preinscripcio?.Batxillerat}
                    onChange={() => handleBatxillerat()}>
                </Checkbox1>
                <Form.Label> Batxillerat </Form.Label>
            </CheckboxWrapper>
            <CheckboxWrapper>
                <Checkbox1
                    type="checkbox"
                    size={3}
                    checked={preinscripcio?.CFGM}
                    onChange={() => handleCFGM()}>
                </Checkbox1>
                <Form.Label> CFGM </Form.Label>
            </CheckboxWrapper>
            <CheckboxWrapper>
                <Checkbox1
                    type="checkbox"
                    size={3}
                    checked={preinscripcio?.CFGS}
                    onChange={() => handleCFGS()}>
                </Checkbox1>
                <Form.Label > CFGS </Form.Label>
            </CheckboxWrapper>
        </Wrap>}
    </Content>);
}

export default Checks;
