
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import Box from "../../components/box";
import { Helmet } from "react-helmet";

const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const Sections = styled.div`
    padding: 24px 0;
`;

const Titulo = styled.h1`
    @media(max-width: 900px){
        font-size: 24px;
    }
`;

/*const SubTitulo = styled.p`
    text-align: center;
    padding-bottom: 24px;

    @media(max-width: 900px){
        font-size: 18px;
    }
`;*/

const Paragraf = styled.div`
    padding-bottom: 24px;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const ImageLeft = styled.img`
    float: left;
    width: 300px;
    height: auto;
    margin: 0 32px 32px 0;
    border-radius: 8px;

    @media(max-width: 900px){
        width: 200px;
        margin: 0 24px 24px 0;
    }

    @media(max-width: 600px){
        width: 100%;
        margin: initial;
        margin-bottom: 24px;
    }
`;

const ImageRight = styled.img`
    float: right;
    width: 300px;
    height: auto;
    margin: 0 0 32px 32px;
    border-radius: 8px;

    @media(max-width: 900px){
        width: 200px;
        margin: 0 0 24px 24px;
    }

    @media(max-width: 600px){
        width: 100%;
        margin: initial;
        margin-bottom: 24px;
    }
`;


function Historia() {
    return (
        <Content >
            <Wrapper>
                <Titulo>Història</Titulo>
                <Helmet>
                    <title>Història</title>
                    <meta name="description" content="Història" />
                </Helmet>
                <Sections>
                    <Box>
                        <Paragraf>
                            <b>L’Institut Escola Municipal de Treball de Granollers</b> és un institut d’ensenyament secundari públic, la titularitat del qual correspon a l’Ajuntament de Granollers i que està sostingut mitjançant un conveni singular amb el Departament d’Ensenyament de la Generalitat de Catalunya.
                        </Paragraf>
                        <ImageLeft src={require('../../assets/img/historia/3.png')} />
                        <Paragraf>
                            L’Institut Escola Municipal de Treball s’ha caracteritzat, al llarg d’aquests més de cent anys d’història, per la seva vinculació amb l’entorn local i comarcal, i també per la seva relació amb el món del treball. Amb una clara vocació educativa, creiem en la formació dels nois i les noies tant des de la dimensió dels valors com des del punt de vista acadèmic. L’Institut EMT és un centre escolar que es declara no confessional i integrador i pel qual esdevé prioritari fomentar la formació del nostre alumnat en els valors de la tolerància, del respecte i del diàleg, i que vetlla per la participació activa dels diferents sectors –pares, alumnes i professorat- en la vida escolar, en el marc d’una societat que volem plural, integradora i democràtica.
                        </Paragraf>
                        <ImageRight src={require('../../assets/img/historia/2.png')} />
                        <Paragraf>
                            Un equip de professores i professors imparteix els diferents estudis amb dedicació i professionalitat.
                        </Paragraf>
                        <Paragraf>
                            El mapa escolar preveu que l’Institut Escola Municipal de Treball imparteixi estudis <b>d’Educació Secundària Obligatòria, Batxillerat, Cicles Formatius de Grau Mitjà i Cicles Formatius de Grau Superior</b>. Entenem que l’oferta d’estudis postobligatoris que ofereix el nostre centre és atractiva pel ventall d’ensenyaments de secundària als quals un alumne/a pot accedir un cop finalitzada l’ESO o, més endavant, amb el Batxillerat.
                        </Paragraf>
                        <Paragraf>
                            Aquest Institut va obtenir el certificat de la norma ISO 9001/2008 el juliol del 2010.
                        </Paragraf>
                    </Box>
                </Sections>
            </Wrapper>
        </Content >
    );
}

export default Historia;