
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import theme from "../../constants/theme";
import { Alert } from "react-bootstrap";
import { Helmet } from 'react-helmet';

const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const H1 = styled.h1`
    padding-bottom: 24px;
    @media(max-width: 900px){
        font-size: 24px;
    }
    @media(max-width: 600px){
        line-height: 28px;
    }
`;

const List = styled.ul`
    margin-bottom: 0;
`;

const Item = styled.li`

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Paragraf = styled.div<{ nopadding?: boolean }>`
    padding-bottom: ${props => props.nopadding ? '0' : '24px'};

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Section = styled.div`
    padding-bottom: 30px;
`;

const SectionHead = styled.div`
    background-color: #c82d2d;
    color: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 12px 16px;
`;

const SectionBody = styled.div`
    background-color: #fff;
    padding: 16px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
    @media(max-width: 600px){
        font-size: 14px;
    }
`;


const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
`;


function OfertaEducativaBatxillerat() {
    return (
        <Content>
            <Helmet>
                <title>Batxillerat</title>
                <meta name="description" content="Batxillerat" />
            </Helmet>
            <Wrapper>
                <H1>Batxillerat</H1>
                <Paragraf>
                    El batxillerat és l'etapa que es cursa normalment entre els 16 i els 18 anys, tot i que és oberta a persones de qualsevol edat, i forma part de l'educació secundària postobligatòria. És un cicle format per dos cursos acadèmics de 30 hores lectives setmanals i es realitza en horari de matí de les 8.00 a 14.30 hores amb un descans de 30 minuts.
                    Es desenvolupa en diverses modalitats, s'organitza de manera flexible en vies diferents dins de cada modalitat per oferir als alumnes una preparació especialitzada en funció de les seves perspectives de formació.
                    Té com a finalitat la formació, orientació i preparació dels alumnes per a la seva incorporació a estudis posteriors, tant professionals com universitaris, i per a l'accés al món laboral.
                </Paragraf>
                <Section>
                    <SectionHead>Accés</SectionHead>
                    <SectionBody>
                        Haver assolit el Graduat en Educació Secundària Obligatòria o haver cursat estudis de sistemes educatius estrangers que hagin estat homologats al títol d'ESO.
                        Haver assolit el títol de tècnic/a d'un cicle formatiu de grau mitjà o de tècnic/a superior d'un cicle formatiu de grau superior.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Organització</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            El batxillerat s'organitza en tres modalitats diferents: arts, ciències i tecnologia, i humanitats i ciències socials.
                            A l'Institut Escola Municipal de Treball s’imparteixen les següents modalitats:
                        </Paragraf>
                        <List>
                            <Item><b>Ciències i tecnologia:</b> adreçada a alumnes interessats per les ciències experimentals, les matemàtiques, els estudis relacionats amb continguts cientificosanitaris i el món dels processos tecnològics i dels materials, instruments, aparells i màquines que s'utilitzen en la producció de béns i serveis.
                            </Item>
                            <Item>
                                <b>Humanitats i ciències socials</b>: adreçada a alumnes amb inquietuds relacionades amb els estudis lingüístics i literaris, la filosofia, les manifestacions culturals, les ciències socials, jurídiques, polítiques i econòmiques, la gestió i administració pública, la comunicació, les relacions públiques, la publicitat, el turisme i altres serveis d'oci.
                            </Item>
                        </List>
                    </SectionBody>
                </Section>

                <Section>
                    <SectionHead>Currículum</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            El currículum del batxillerat consta de:
                        </Paragraf>
                        <List>
                            <Item><b>Part comuna</b>: les matèries comunes i el treball de recerca.
                            </Item>
                            <Item><b>Part de modalitat</b>: la matèria obligada de la modalitat, les matèries de modalitat i les matèries optatives.</Item>
                        </List>
                        <br />
                        <Paragraf>
                            A més, els alumnes tenen assignada una hora setmanal de tutoria.
                            En començar el batxillerat l'alumne/a escull una modalitat i ha de cursar les matèries de la modalitat escollida.
                        </Paragraf>
                        <Paragraf nopadding={true}>
                            Consulta el &nbsp;
                            <Link href="https://drive.google.com/file/d/1sKDvDbiwrfj9mOs69oOrRdtTfgw3p6Lr/view?usp=sharing" target="_blank">
                                Currículum de Batxillerat</Link>
                        </Paragraf>
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Qualificacions</SectionHead>
                    <SectionBody>
                        Cada trimestre hi haurà una sessió qualificadora de la junta d'avaluació. A final de curs els alumnes amb matèries suspeses hauran de fer les proves extraordinàries de recuperació pels alumnes de 1r i 2n curs. A més, per facilitar l'adaptació, a l'inici del 1r curs es fa una preavaluació.
                        Per poder cursar el 2n curs caldrà haver aprovat totes les matèries de 1r curs o tenir un màxim de dues matèries suspeses. L'alumnat que no accedeixi a 2n curs si té 3 o 4 matèries suspeses podrà repetir tot el curs o només repetir les matèries pendents, si en té més de 4 haurà de tornar a cursar totalment el 1r curs.
                        Les qualificacions finals de les matèries al llarg de tot el batxillerat són numèriques d'1 al 10.
                        La qualificació final del batxillerat s'obté per mitjana aritmètica de les qualificacions obtingudes en les matèries cursades, que és un 90% de la nota final, i el treball de recerca que representa el 10% restant.
                        La permanència en el batxillerat és de quatre anys com a màxim.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Treball de recerca</SectionHead>
                    <SectionBody>
                        El treball de recerca és una activitat d'investigació que han de realitzar tots els alumnes de batxillerat amb l'orientació i seguiment del professorat. S'inicia durant el primer curs i es presenta obligatòriament durant el segon curs.
                        Cada any els millors treballs es poden presentar a diversos premis. (Premi Camí Ral, Premi Ramon Llull, Premi Universitat de Vic, Premi Ernest Lluch...)
                    </SectionBody>
                </Section>
            </Wrapper>
        </Content>
    );
}

export default OfertaEducativaBatxillerat;