
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';
import theme from '../constants/theme';
import { ROUTES } from '../constants/routes';



const Content = styled.div``;

const Overlay = styled.div`
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
    z-index: 10;
`;

const BannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;

  padding: 24px;
  text-align: center;
  z-index: 1000;
  display: flex; 
  justify-content: center; 
  align-items: center;

  @media(max-width: 600px){
        padding: 16px;
    }
`;

const Button = styled.button`
  background-color: #c82d2d;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
`;

const Text = styled.div`
    text-align: center; 
    max-width: 600px;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
`;

const CookieConsentBanner: React.FC = () => {

    const [isVisible, setIsVisible] = useState<boolean>(true);

    const handleAcceptCookies = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setIsVisible(false);
    };
    React.useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (cookiesAccepted === 'true') setIsVisible(false);
    }, []);

    if (!isVisible) return null;

    return (
        <Content>
            <Overlay />
            <BannerContainer>
                <Text>
                    Aquest lloc web utilitza cookies per millorar la seva experiència.
                    En utilitzar el nostre lloc web, accepta la nostra
                    {' '}<Link href={ROUTES.PolicyPrivacy} target='_blank'>política de privacitat</Link> {' '}
                    i <Link href={ROUTES.PolicyCookies} target='_blank'>política de cookies</Link>
                </Text>
                <Button onClick={handleAcceptCookies}>D'acord</Button>
            </BannerContainer>
        </Content >
    );
};

export default CookieConsentBanner;
