import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";
import { IBooks } from "../types/Books";
import { ICalendar } from "../types/StudentFamily";


export namespace StudentsFamilyApi {

    const table = 'students-family';

    export const ListBooks = async (): Promise<IBooks> => {
        const result = await getDoc(doc(firestore, table, 'books'));
        return result.data() as any;
    }

    export const ListCalendar = async (): Promise<ICalendar> => {
        const result = await getDoc(doc(firestore, table, 'calendar'));
        return result.data() as any;
    }

    export const Update = async (id: string, data: any) => {
        const docRef = doc(collection(firestore, table), id);
        await updateDoc(docRef, data);
    };
}