
import styled from "styled-components";
import WrapperPage from "../components/wrapper-page";
import Wrapper from "../components/wrapper";
import { useState } from "react";
import theme from "../constants/theme";
import { Alert } from "react-bootstrap";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { Helmet } from 'react-helmet';

const Content = styled.div`
    padding: 48px 0;

    @media (max-width: 900px){
        padding: 0;
    }
`;

const H1 = styled.h1`
    font-size: 38px;
    font-weight: bold;
    padding-bottom: 24px;

    @media(max-width: 900px){
        font-size: 24px;
        margin-bottom: 0;
    }
`;

const Sections = styled.div`
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: center;
    align-items: start;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
    border-radius: 8px;

    @media (max-width: 1000px){
        flex-direction: column;
    }

    @media (max-width: 600px){
        gap: 24px;
        margin: 24px 0 0;
        border-bottom: initial;
        padding-bottom: 24px;
    }
`;

const Title = styled.div`
    font-size: 28px;
    font-weight: bold;

    @media (max-width: 1000px){
        font-size: 21px;
    }
`;

const TitleParagraph = styled.div`
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 24px;

    @media (max-width: 600px){
        font-size: 21px;
        line-height: 24px;
    }
`;

const Text = styled.div`
    width: calc(100% - 250px);
    display: flex;
    flex-direction: start;
    align-items: start;
    flex-direction: column; 
    gap: 12px;
    background-color: #fff;
    padding: 32px 24px;
    border-radius: 12px;
    height: 100%;

    @media (max-width: 1000px){
        width: 100%;
    }

    @media (max-width: 600px){
        padding: 0 16px 16px;
    }
`;

const DescriptionDepartaments = styled.div`
    font-size: 16px;
    line-height: 28px;

    @media (max-width: 600px){
        font-size: 14px;
        line-height: 24px;
    }
`;

const DescriptionWrapper = styled.div`
    display: flex;
`;

const Description = styled.div<{ expanded: boolean }>`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: ${props => (props.expanded ? 'none' : '4')};
    line-clamp: ${props => (props.expanded ? 'none' : '4')};
    transition: -webkit-line-clamp 0.3s ease;

    @media (max-width: 600px){
        font-size: 14px;
    }
`;

const DescriptionPadding = styled.div`
    font-size: 16px;
    line-height: 28px;
    padding-bottom: 16px;

    @media (max-width: 600px){
        font-size: 14px;
        line-height: 24px;
    }
`;

const ImageWrapper = styled.div`
    width: 250px;
    height: 250px;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    padding: 32px 24px;

    @media (max-width: 1000px){
        width: 100%;
        padding: 0px;
        border-radius: 0;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }

    @media (max-width: 600px){
        height: 200px;
    }
`;

const Image = styled.img`
    width: 100%; 
    height: 100%;
    object-fit: cover;
    filter: sepia(.3);
    border-radius: 50%;

    @media (max-width: 1000px){
        border-radius: initial;
    }
`;

const List = styled.ul`
    @media (max-width: 600px){
        padding-left: 24px;
    }
`;

const Item = styled.li`
    line-height: 30px;

    @media (max-width: 600px){
        line-height: 21px;
        font-size: 14px;
        line-height: 24px;
    }
`;

const Paragraph = styled.div`
    padding-top: 48px;
`;

const ListFlex = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start; 
    align-items: start; 
    width: 100%;
    padding: 24px 0;
`;

const ItemFlex = styled.div`
    flex: 1 1 33.33%; 
    max-width: 300px; 
    margin: 8px;
    background-color: white; 
    box-sizing: border-box;
    display: flex;
    flex-direction: column; 
    padding: 12px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;

    @media ( max-width: 450px){
        flex: initial;
        margin: 4px;
    }
`;

export const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
`;

const Expand = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: start;    
`;

function Tutors() {

    const [isExpanded, setIsExpanded] = useState<number>(0);

    const toggleExpand = (event: any, id: number) => {
        event.stopPropagation();
        if (id === isExpanded) setIsExpanded(0);
        if (id !== isExpanded) setIsExpanded(id);
    }


    return (
        <Content>
            <Helmet>
                <title>Professorat</title>
                <meta name="description" content="Professorat" />
            </Helmet>
            <WrapperPage>
                <Wrapper>
                    <H1>Professorat</H1>
                    <Sections>
                        <Section>
                            <ImageWrapper>
                                <Image src="https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/tutors_claustre.jpg?alt=media&token=3c5bf4f5-6218-4ee4-95e1-63dcaeb8f303" />
                            </ImageWrapper>
                            <Text>
                                <Title>Claustre</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 1}>
                                        Som un equip  d'uns 140 professors que impartim classes a ESO, Batxillerat i Cicles Formatius. La nostra tasca implica la formació constant i la comunicació entre el claustre per continuar millorant les metodologies i aconseguir un bon ambient i harmonia al centre. El nostre rol actual com a docents consisteix en ser facilitador, acompanyant i guia del procés d’aprenentatge de l’alumnat.
                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 1)}>
                                        {isExpanded === 1 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                        <Section>
                            <ImageWrapper>
                                <Image src="https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/tutors_eso.jpg?alt=media&token=05137941-1a5c-423d-b20c-9f3b3e2c2b9a" />
                            </ImageWrapper>
                            <Text>
                                <Title>ESO</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 2}>
                                        El professorat d’ESO s’organitza en equips de nivell, que es reuneixen cada setmana amb el/la coordinador/a amb els següents objectius:
                                        <List>
                                            <Item>Realitzar un seguiment dels alumnes del curs.</Item>
                                            <Item>Organitzar les diferents activitats previstes: tallers, sortides i noves propostes.</Item>
                                            <Item>Coordinar les activitats de tutoria recollides en el PAT (Pla d’Acció Tutorial).</Item>
                                            <Item>Desenvolupar projectes transversals i el treball de síntesi.</Item>
                                            <Item>Corregir i proposar mesures de millora de la convivència al centre.</Item>
                                            <Item>Informar dels acords i de les decisions a l l’equip directiu.</Item>
                                            <Item>Paral·lelament, els/les coordinadors/es de cada nivell es reuneixen setmanalment amb el/la coordinador/a pedagògica del centre.</Item>
                                        </List>
                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 2)}>
                                        {isExpanded === 2 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                        <Section>
                            <ImageWrapper>
                                <Image src="https://firebasestorage.googleapis.com/v0/b/ies-emt.appspot.com/o/tutors_postobligatori.jpg?alt=media&token=a2cdc40c-a74e-46ea-a646-d5f86dd2a7fd" />
                            </ImageWrapper>
                            <Text>
                                <Title>Postobligatori</Title>
                                <DescriptionWrapper>
                                    <Description expanded={isExpanded === 3}>
                                        El professorat de Batxillerat es reuneix, totalment, per nivells o només els tutors amb el/la coordinador/a, amb els següents objectius:
                                        <List>
                                            <Item>Realitzar un seguiment de l’alumnat del curs</Item>
                                            <Item>Coordinar les activitats de tutoria recollides en el PAT (Pla d'Acció Tutorial).</Item>
                                            <Item>Vetllar pel correcte desenvolupament de totes les fases que comprenen la realització del Treball de Recerca.</Item>
                                            <Item>Corregir i proposar mesures de millora de la convivència al centre.</Item>
                                            <Item>Informar dels acords i de les decisions a l'equip directiu.</Item>
                                        </List>
                                        El professorat de Cicles Formatius s’organitza en equips docents. Aquests equips, formats pel professorat de cada curs, es reuneixen:
                                        <List>
                                            <Item>a l’inici de curs amb el cap d’estudis d’FP, per organitzar el curs i l’acollida de l’alumnat.</Item>
                                            <Item>a l’avaluació diagnòstica d’inici de curs, per detectar alumnes que tenen dificultats durant el curs i necessiten ajuda en el seu procés d’aprenentatge per, si és necessari, vincular-los amb el Departament d’Orientació del centre.</Item>
                                            <Item>a la preavaluació, per fer una primera avaluació de l’alumnat nou per tal d’informar a les famílies del seu procés d’adaptació als nous estudis.</Item>
                                            <Item>a les avaluacions (una per trimestre i l’extraordinària), per avaluar a l’alumnat i transmetre les pautes per la seva millora acadèmica.</Item>
                                            <Item>quan ho proposa el tutor de grup, per coordinar activitats i projectes i proposar millores en la convivència del grup classe.</Item>
                                        </List>
                                    </Description>
                                    <Expand onClick={(e) => toggleExpand(e, 3)}>
                                        {isExpanded === 3 ? <RiArrowUpSLine size="2em" /> : <RiArrowDownSLine size="2em" />}
                                    </Expand>
                                </DescriptionWrapper>
                            </Text>
                        </Section>
                    </Sections>
                    <Paragraph>
                        <TitleParagraph>Els departaments</TitleParagraph>
                        <DescriptionDepartaments>
                            L’activitat acadèmica del centre s’assenta sobre l’estructura dels departaments, veritables punts neuràlgics de l’activitat docent de centre. Cada docent només està adscrit a un sol departament, en funció de la majoria d’hores lectives que realitzi en cada curs escolar.                            Setmanalment, els docents de cada departament es reuneixen amb el/la cap de departament. També es realitzen projectes que estan lligats a cada departament i alguns que es treballen de manera transversal.
                            El professorat queda distribuït en els següents departaments:

                            <ListFlex>
                                <ItemFlex>Llengua Catalana</ItemFlex>
                                <ItemFlex>Llengua Castellana</ItemFlex>
                                <ItemFlex>Llengua estrangera</ItemFlex>
                                <ItemFlex>Ciències Socials</ItemFlex>
                                <ItemFlex>Matemàtiques</ItemFlex>
                                <ItemFlex>Ciències i Tecnologia</ItemFlex>
                                <ItemFlex>Visual i Plàstica i Música</ItemFlex>
                                <ItemFlex>Educació Física</ItemFlex>
                                <ItemFlex>Orientació educativa</ItemFlex>
                                <ItemFlex>Administració</ItemFlex>
                                <ItemFlex>Automoció</ItemFlex>
                                <ItemFlex>Electricitat</ItemFlex>
                                <ItemFlex>Mecànica</ItemFlex>
                                <ItemFlex>Química</ItemFlex>
                                <ItemFlex>Sanitat</ItemFlex>
                            </ListFlex>
                        </DescriptionDepartaments>
                    </Paragraph>
                    <Paragraph>
                        <TitleParagraph>Organigrama de l’equip directiu</TitleParagraph>
                        <List>
                            <Item><b>Directora</b>: Montserrat Bosch</Item>
                            <Item><b>Secretària</b>: Eulàlia Castells</Item>
                            <Item><b>Administrador econòmic</b>: Jordi Hernández</Item>
                            <Item><b>Sots director / Cap d’Estudis d’ESO i Batxillerat</b>: Antoni Corral</Item>
                            <Item><b>Cap d’Estudis de Formació Professional</b>: Enric Pagès</Item>
                            <Item><b>Cap d’Estudis d’empresa</b>: Josep Mª Garcia</Item>
                            <Item><b>Coordinació pedagògica</b>: Laura Pou</Item>
                        </List>
                    </Paragraph>
                    <Paragraph>
                        <TitleParagraph>Vols treballar amb nosaltres?</TitleParagraph>
                        <DescriptionPadding>
                            L'Institut Escola Municipal de Treball és un institut de titularitat Municipal i la contractació del personal depèn de l'Ajuntament de Granollers, per tant, no ens nodrim de la Borsa de Substitucions del Departament d'Educació de la Generalitat de Catalunya.
                        </DescriptionPadding>
                        <DescriptionPadding>
                            Per treballar amb nosaltres has d'entrar les teves dades curriculars a <Link href="https://valid.aoc.cat/o/oauth2/auth?client_id=tramits.granollers3.cat&scope=autenticacio_usuari&redirect_uri=https://aplicacions.granollers.cat/valid_login&response_type=code&state=QXBsaWNhY2lvbnNHcmFub2xsZXJz" target="_blank">Dades curriculars</Link>.
                            Cal posar la titulació o titulacions habilitants per la docència i l'especialitat o especialitats a què et presentes a l'apartat d'observacions.
                        </DescriptionPadding>
                        <DescriptionPadding>
                            La inscripció és GRATUÏTA.
                        </DescriptionPadding>
                        <DescriptionPadding>
                            Un cop feta la inscripció s'ha d'estar atent a les ofertes i registrar-vos a l'oferta de professorat <Link href="https://seuelectronica.granollers.cat/portal/sede/se_contenedor1.jsp?seccion=s_ldes_d1_v2.jsp&codbusqueda=51&language=ca&codResi=1&codMenuPN=21&codMenu=239&layout=se_contenedor1.jsp&buscadorTipoOfertaSeleccionado=103&buscadorEstadoOfertaSeleccionado=106&layout=se_contenedor1.jsp" target="_blank">Vull inscriure'm a un procés de selecció</Link> a través de la Seu Electrònica de l'Ajuntament de Granollers, dins l'espai "Treballa a l'Ajuntament"/ Borsa de treball/ Vull inscriure'm a un procés de selecció.
                        </DescriptionPadding>
                    </Paragraph>
                </Wrapper>
            </WrapperPage>
        </Content>
    );
}

export default Tutors;