

import Header from "./header";
import RouterHub from "./router-hub";
import Footer from './footer';
import styled from "styled-components";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./scroll-top";
import { GlobalStyle } from "./global-style";
import Promo from "./promo";
import CookieConsentBanner from "./CookieConsentBanner";
import PromoAlert from "./promo_alert";
import { IniciConfigProvider } from "../context/main-provider";

export const Content = styled.div``;

function App() {
  return (
    <Content>
      <IniciConfigProvider>
        <GlobalStyle />
        <BrowserRouter>
          <ScrollToTop />
          <Promo />
          <PromoAlert />
          <Header />
          <RouterHub />
          <Footer />
        </BrowserRouter>
        <CookieConsentBanner />
      </IniciConfigProvider>
    </Content>
  );
}

export default App;
