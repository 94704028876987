
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { User } from '../../../types/User';
import { UsersApi } from '../../../api/users';
import { RoleEnum } from '../../../constants/enums';
import { FaTrashAlt } from 'react-icons/fa';

const Content = styled.div``;

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 12px;
`

const DeleteTr = styled.td`
    width: 60px;
`

export interface UserListProps {
    refresh: boolean
}

export function UserList(props: UserListProps) {
    const [users, setUsers] = useState<User[] | null>(null);
    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        UsersApi.List()
            .then((x: User[]) => setUsers(x))
            .catch(x => console.log(x));
    }, [props.refresh, refresh]);

    const handlerRemove = (id: string) => {
        if (!window.confirm('Estas segur de borrar el usuari?')) return;
        UsersApi.Delete(id)
            .then(x => setRefresh(!refresh))
            .catch(x => console.log(x));
    }
    return (
        <Content>
            <Title>Usuaris</Title>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Esborra</th>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Rol</th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.length > 0 && users.map(x =>
                        <tr key={x.id}>
                            <DeleteTr onClick={() => handlerRemove(x.id!)}>
                                <FaTrashAlt />
                            </DeleteTr>
                            <td>{x.name}</td>
                            <td>{x.email}</td>
                            <td>{RoleEnum[x.role!]}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Content>
    );
}