
import styled from "styled-components";
import Wrapper from "../../../components/wrapper";
import { HiDownload } from "react-icons/hi";
import Box from "../../../components/box";
import { Helmet } from 'react-helmet';

const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const Info = styled.div`
    padding: 24px 0;
    @media(max-width: 600px){
        font-size: 14px;
    }
`;


const Titulo = styled.h1`
    @media(max-width: 900px){
        font-size: 24px;
    }
`;

const Subtitle = styled.div`
    font-size: 21px;
    font-weight: bold;
    padding-bottom: 24px;

    @media(max-width: 600px){
        font-size: 18px;
        padding-bottom: 12px;
        line-height: 21px;
    }
`;

const Description = styled.div`
    padding-bottom: 24px;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Section = styled.div`
    padding-bottom: 48px;
`;

const DescriptionNoPadding = styled.div`

    @media(max-width: 600px){
        font-size: 14px;
    }   
`;

const List = styled.ul``;

const Item = styled.li`
        @media(max-width: 600px){
        font-size: 14px;
    }
`;


function TramitsConvalidacioEso() {

    const openUrl = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    return (
        <Content >
            <Helmet>
                <title>Convalidacions música ESO</title>
                <meta name="description" content="Convalidacions música ESO" />
            </Helmet>
            <Wrapper>
                <Titulo>Convalidacions música ESO</Titulo>

                <Info>
                    La sol·licitud es pot presentar <b>fins al darrer dia hàbil de setembre</b> juntament amb tota la documentació necessària.
                    <b> Molt important! L’alumne/a haurà de continuar assistint a classe fins que se li faci entrega de la resolució de la convalidació per part de la direcció del centre. </b>
                    Les matèries que s’hagin convalidat no tenen qualificació ni computen a l’efecte de càlcul de la nota mitjana.
                </Info>
                <Section>
                    <Subtitle>Alumnes d’ESO que cursen estudis de música en un conservatori o en un centre professional</Subtitle>
                    <Box>
                        <Description>
                            Es poden convalidar les matèries següents:
                        </Description>
                        <List>
                            <Item>
                                Música (la matèria comuna de primer a tercer curs d’ESO).</Item>
                            <Item>Les matèries optatives de primer a tercer curs d’ESO (2 hores setmanals per curs).</Item>
                            <Item> Una matèria optativa de quart curs d’ESO (fins a un màxim de 4 hores setmanals).</Item>
                        </List>
                        <DescriptionNoPadding>
                            No són convalidables les matèries de Religió, ni el Treball de Síntesi (de primer a tercer), ni Educació en Valors Cívis i Ètics, ni el Projecte de Recerca de quart curs.
                        </DescriptionNoPadding>
                    </Box>
                </Section>
                <Section>
                    <Subtitle> Alumnes d’ESO que cursen estudis de música en una escola de música autoritzada </Subtitle>
                    <Box>
                        <Description>
                            Situació 1: Alumnes amb una dedicació horària a l’escola de música d’entre 3 i 4 hores setmanals. Se’ls poden reconèixer les matèries següents:
                        </Description>
                        <List>
                            <Item>Les matèries optatives de primer a tercer curs d’ESO.</Item>
                            <Item>Una matèria optativa de quart curs d’ESO (fins a un màxim de 2 hores setmanals).</Item>
                        </List>
                        <Description>
                            Situació 2. Alumnes amb una dedicació horària a l’escola de música de 4 hores setmanals o més. Se’ls poden reconèixer les matèries següents:
                        </Description>
                        <List>
                            <Item>Música (la matèria comuna de primer a tercer curs d’ESO).</Item>
                            <Item>Les matèries optatives de primer a tercer curs d’ESO.</Item>
                            <Item>Una matèria optativa de quart curs d’ESO (fins a un màxim de 4 hores setmanals).</Item>
                        </List>
                        No són convalidables les matèries de Religió, ni el Treball de Síntesi (de primer a tercer), ni Educació en Valors Cívis i Ètics, ni el Projecte de Recerca de quart curs.
                    </Box>
                </Section>

                <Section>
                    <Subtitle>Documentació que cal presentar en les oficines del centre</Subtitle>
                    <Box>
                        <List>
                            <Item onClick={() => openUrl('https://drive.google.com/file/d/1CSMfQPuofGqSNiq8-65MwL0zD-N2Sum-/view?usp=sharing')}>Sol·licitud de reducció de matèries de l’educació secundària obligatòria (ESO) per als alumnes que cursen estudis de música. <HiDownload color='#343a40' size="1em" /></Item>
                            <Item onClick={() => openUrl('https://drive.google.com/file/d/1ldylSR-KnfDe5DGPwkQBpabc_6nZ03e5/view?usp=sharing')}>Autorització sortida del centre i responsabilitat del pare/mare/tutor de les hores que el/la seu/va fill/a no assisteix al centre per la convalidació de música. <HiDownload color='#343a40' size="1em" /></Item>
                            <Item>Certificat expedit pel conservatori o escola de música on s’acrediti el nombre d’hores de dedicació setmanal.</Item>
                        </List>
                    </Box>
                </Section>


            </Wrapper>
        </Content >
    );
}

export default TramitsConvalidacioEso;