
import Logo from './logo';
import styled from "styled-components";
import { ROUTES } from '../constants/routes';
import { useNavigate } from 'react-router-dom';
import { Navbar, Offcanvas } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { BiMenu } from 'react-icons/bi';
import HeaderRoutes from './header-routes';

const Content = styled.div`
    position: sticky;
    top:0;
    z-index: 5;
`;

const HeaderContent = styled.div<{ showBoxShadow: boolean }>`
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${props => props.showBoxShadow && '0 3px 3px 0 #373b3e11'};
`;

const Wrapper = styled.div`
    width: 100%;
    max-width: 1244px;
    display: flex;
    padding: 21px;

    @media(max-width: 930px){
          padding: 18px;
    }

    @media(max-width: 600px){
          padding: 9px;
    }
`;

const Brand = styled.div`
    width: 152px;
    display: flex;
    align-items: center;
    justify-content: left;
`;

const Menu = styled.div`
    width: calc(100% - 152px);
    display: flex;
    justify-content: right;

    @media(max-width: 930px){
        display: none;
    }
`;

const MenuMobile = styled.div`
    display: none;

    @media(max-width: 930px){
        width: calc(100% - 152px);
        display: flex;
        align-items: center;
        justify-content: right;
    }
`

const Toggle = styled(Navbar.Toggle)`
    display: none;
    
    @media(max-width: 930px){
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: right;
        svg{
            fill: #343a40!important;
            width: 24px;
            height: 24px;
        }
    }
`

const OffcanvasStyled = styled(Offcanvas)`
    background-color: #fff;
    max-width: 300px;
`;

const ModalTitle = styled(Offcanvas.Title)`
    color: #343a40;
    font-weight: bold;
`;


function Header() {
    const navigate = useNavigate();
    const [showMenuMobile, setShowMenuMobile] = useState<boolean>(false);
    const [scrollTop, setScrollTop] = useState<boolean>(false);

    const handleClose = () => setShowMenuMobile(false);
    const handleShow = () => setShowMenuMobile(true);

    useEffect(() => {
        const handleScroll = () => setScrollTop(window.scrollY > 0);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Content>
            <HeaderContent showBoxShadow={scrollTop}>
                <Wrapper>
                    <Brand onClick={() => navigate(ROUTES.Home)}>
                        <Logo />
                    </Brand>
                    <Menu>
                        <HeaderRoutes />
                    </Menu>
                    <MenuMobile>
                        <Toggle onClick={handleShow}>
                            <BiMenu />
                        </Toggle>
                        <OffcanvasStyled
                            show={showMenuMobile}
                            onHide={handleClose}
                            placement='end'>
                            <Offcanvas.Header closeButton>
                                <ModalTitle>Menu</ModalTitle>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <HeaderRoutes handleCloseMobile={handleClose} />
                            </Offcanvas.Body>
                        </OffcanvasStyled>
                    </MenuMobile>
                </Wrapper>
            </HeaderContent>
        </Content>
    );
}

export default Header;