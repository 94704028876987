
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import theme from "../../constants/theme";
import Spinner from 'react-bootstrap/Spinner';

import { useEffect, useState } from "react";
import { ConfigApi } from "../../api/config";
import { IPreinscripcioConfig } from '../../types/Config';
import { ItinerariEnum } from '../../constants/enums';
import Timeline from "../../components/preinscripcions/timeline";
import { ROUTES } from "../../constants/routes";
import { Helmet } from 'react-helmet';

const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const Titulo = styled.h1`
    padding-bottom: 24px;
    @media(max-width: 900px){
        font-size: 24px;
        line-height: 24px;
    }
`;

const Paragraf = styled.div`
    padding-bottom: 24px;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
`;

const SpinnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
`;

const Instruccions = styled.div`
    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Gencat = styled.div`
    padding-bottom: 32px;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const TimelineWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    align-content: center;
`;

const Menu = styled.div`
    display: flex; 
    align-items: center; 
    justify-content: start;
    flex-direction: row;
    margin-bottom: 24px;

    @media(max-width: 600px){
        justify-content: center;
    }
`;

const MenuItem = styled.div<{ selected: boolean }>`
    padding: 12px 24px;
    color: ${props => props.selected ? '#fff' : 'inherit'};
    background-color: ${props => props.selected ? '#c82d2d' : '#fff'};
    font-weight: ${props => props.selected ? 'bold' : 'initial'};
    cursor: pointer;
    border-right: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
    text-align: center;


    :first-child{
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        padding-left: 48px; 
    }

    :last-child{
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        padding-right: 48px; 
        border-right: initial;
    }

    @media(max-width: 600px){

        :first-child{
            padding-left: 24px; 
        }

        :last-child{
            padding-right: 24px; 
        }
    }


    @media(max-width: 600px){
        padding: 12px;
    }
`;


function PreinscripcioIMatricula() {
    const { type } = useParams();
    const [preinscripcio, setPreinscripcio] = useState<IPreinscripcioConfig | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        ConfigApi.GetById("preinscripcio")
            .then((x: IPreinscripcioConfig) => setPreinscripcio(x))
            .catch(x => console.log(x))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (type && !(type in ItinerariEnum)) navigate('/not-found/404');
    }, [type, navigate]);

    const renderLink = () => {
        return <Link
            target="_blank"
            href="https://preinscripcio.gencat.cat/ca/inici/index.html">Gencat
        </Link>
    }

    const getItinerariEnum = (value: string): ItinerariEnum | undefined => {
        if (!Object.keys(ItinerariEnum).includes(value)) return undefined;
        return ItinerariEnum[value as keyof typeof ItinerariEnum];
    }

    const getInstruccions = () => {
        if (type === ItinerariEnum[ItinerariEnum.eso]) return preinscripcio?.InstruccionsUrlESO;
        if (type === ItinerariEnum[ItinerariEnum.batxillerat]) return preinscripcio?.InstruccionsUrlBatxillerat;
        if (type === ItinerariEnum[ItinerariEnum.cfgm]) return preinscripcio?.InstruccionsUrlCFGM;
        if (type === ItinerariEnum[ItinerariEnum.cfgs]) return preinscripcio?.InstruccionsUrlCFGS;
    }

    const handleClick = (route: string) => {
        navigate(route);
    }

    return (
        <Content >
            <Helmet>
                <title>Preinscripció i Matrícula</title>
                <meta name="description" content="Preinscripció i Matrícula" />
            </Helmet>
            <Wrapper>
                <Titulo>Preinscripció i Matrícula {/*type?.toUpperCase()*/}</Titulo>
                <Menu>
                    <MenuItem
                        selected={type === ItinerariEnum[ItinerariEnum.eso]}
                        onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/eso`)}>
                        ESO</MenuItem>
                    <MenuItem
                        selected={type === ItinerariEnum[ItinerariEnum.batxillerat]}
                        onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/batxillerat`)}>
                        Batxillerat
                    </MenuItem>
                    <MenuItem
                        selected={type === ItinerariEnum[ItinerariEnum.cfgm]}
                        onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/cfgm`)}>
                        CFGM</MenuItem>
                    <MenuItem
                        selected={type === ItinerariEnum[ItinerariEnum.cfgs]}
                        onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/cfgs`)}>
                        CFGS</MenuItem>
                </Menu>
                {loading ?
                    <SpinnerWrapper>
                        <Spinner variant="danger" />
                    </SpinnerWrapper>
                    :
                    (type === ItinerariEnum[ItinerariEnum.eso] && preinscripcio?.ESO === false) ||
                        (type === ItinerariEnum[ItinerariEnum.batxillerat] && preinscripcio?.Batxillerat === false) ||
                        (type === ItinerariEnum[ItinerariEnum.cfgm] && preinscripcio?.CFGM === false) ||
                        (type === ItinerariEnum[ItinerariEnum.cfgs] && preinscripcio?.CFGS === false) ?
                        <Paragraf>
                            La informació de preinscripcions i matrícula pel curs 2024/25 estarà disponible properament.
                            Per a més informació, també podeu consultar el web {renderLink()}
                        </Paragraf>
                        :
                        <>
                            <Instruccions>
                                Llegiu les instruccions per a la <Link target="_blank" href={getInstruccions()}>preinscripció</Link>
                            </Instruccions>
                            <Gencat>
                                Si és necessari, demana cita prèvia presencial per a la preinscripció a <Link target="_blank" href="https://aplicacions.ensenyament.gencat.cat/pls/apex/f?p=CITA_PREVIA:4::::4:P4_TIPUS_CALENDARI,P4_DOMINI:01_CGS,08017852" >gencat.cat</Link>
                            </Gencat>
                            <TimelineWrapper>
                                <Timeline itinerari={getItinerariEnum(type!)!} />
                            </TimelineWrapper>
                        </>
                }
            </Wrapper>
        </Content >
    );
}

export default PreinscripcioIMatricula;