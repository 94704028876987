
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import { Helmet } from 'react-helmet';

const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const H1 = styled.h1`
    padding-bottom: 24px; 
    @media(max-width: 900px){
        font-size: 24px;
    }
    @media(max-width: 600px){
        line-height: 28px;
    }
`;

const Section = styled.div`
    padding-bottom: 30px;
`;

const SectionHead = styled.div`
    background-color: #c82d2d;
    color: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 12px 16px;
`;

const SectionBody = styled.div`
    background-color: #fff;
    padding: 16px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const List = styled.ul`
    margin-bottom: 0;
`;

const Item = styled.li`

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Paragraf = styled.div<{ nopadding?: boolean }>`
    padding-bottom: ${props => props.nopadding ? '0' : '24px'};

    @media(max-width: 600px){
        font-size: 14px;
    }
`;


const ListCourse = styled.div`
    display: flex; 
    justify-content: start;
    align-items: center;
    gap: 16px;  
    flex-direction: row;
    flex-wrap: wrap;
    margin: 24px 0;
`;

const Course = styled.div`
    flex: 0 0 calc(34.333% - 24px);      

    @media(max-width: 1300px){
        flex: 0 0 calc(50% - 12px);   
    }

    @media (max-width: 900px){
        flex: 0 0 100%;  
    }
`;

const CourseHead = styled.div`
    padding: 12px;
    background-color: #a7a7a7;
    color: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
`;
const CourseBody = styled.div`
    padding: 16px;
    background-color: #fff;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
`;
const CourseItem = styled.div``;


function OfertaEducativaCFGS() {
    return (
        <Content >
            <Helmet>
                <title>Cicles Formatius de Grau Superior</title>
                <meta name="description" content="Cicles Formatius de Grau Superior" />
            </Helmet>
            <Wrapper>
                <H1>Cicles Formatius de Grau Superior</H1>
                <Paragraf>
                    La <b>formació professional</b> capacita per a l'exercici qualificat de diverses professions i proporciona la formació necessària per adquirir en competències professionals i els coneixements propis de cada sector, així com les competències transversals (capacitat de resolució de problemes, organització del treball, responsabilitat, treball en equip, autonomia, organització, iniciativa) que permeten mantenir un lloc de treball.

                </Paragraf>
                <Section>
                    <SectionHead>Organització</SectionHead>
                    <SectionBody>
                        Els cicles s'agrupen en famílies professionals i tenen una durada de dos cursos acadèmics: n'hi ha de 1400 i de 2000 hores. Una part d'aquestes hores es destina a la formació en un centre educatiu i una altra part a la formació pràctica en centres de treball.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Accés al Grau Superior</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            Per accedir als Cicles Formatius de Grau Superior s’ha de disposar d’un dels requisits següents
                        </Paragraf>
                        <List>
                            <Item>Títol de Batxillerat.</Item>
                            <Item>Títol de tècnic superior (CFGS) o tècnic especialista (FPII).</Item>
                            <Item>Titulació universitària o equivalent.</Item>
                            <Item>Títol de tècnic (CFGM).</Item>
                            <Item>Haver superat la prova d’accés als cicles formatius de grau superior. </Item>
                            <Item>Haver superat el curs d’orientació universitària (COU) o equivalent.</Item>
                            <Item>Haver superat la prova d’accés a la universitat per a majors de 25 anys.</Item>
                            <Item>Altres estudis equivalents a efectes acadèmics.</Item>
                        </List>
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Currículum</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            El currículum consta de mòduls relacionats amb l’especialitat professional i la formació laboral. Es realitzen aproximadament 500 hores de pràctiques de formació obligatòries a les empreses del sector mitjançant convenis, DUAL GENERAL.
                        </Paragraf>
                        <Paragraf nopadding>
                            A L’EMT promovem l'emprenedoria per impulsar la iniciativa i la capacitat d'innovació de l’alumnat, mentre integrem tecnologies de la Indústria 4.0 per garantir la seva preparació per a les necessitats del mercat laboral modern i futur.
                        </Paragraf>
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>DUAL INTENSIVA:</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            La formació professional dual intensiva és una modalitat de l’FP que combina l’aprenentatge en una empresa amb la formació acadèmica en el centre educatiu.

                        </Paragraf>
                        <Paragraf>
                            Es faran aproximadament <b>1000 hores</b> de formació a l’empresa.
                        </Paragraf>
                        <Paragraf nopadding>
                            La formació a l’empresa serà remunerada i amb alta a la Seguretat Social.
                            Els alumnes que no realitzin la formació en modalitat dual, faran les pràctiques a l’empresa i tota la formació al centre en formació Dual general.
                        </Paragraf>
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Titulació</SectionHead>
                    <SectionBody>
                        Amb la superació d'un cicle formatiu de grau superior s'obté el títol de tècnic superior.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Cicles formatius de Grau Superior (CFGS)</SectionHead>
                    <SectionBody>
                        <Paragraf nopadding>
                            Impartim els següents cicles formatius que tenen una durada de dos cursos acadèmics en horari de tarda:
                        </Paragraf>
                        <ListCourse>
                            <Course>
                                <CourseHead>Administració i finances</CourseHead>
                                <CourseBody>
                                    <CourseItem>Família d'administració i gestió</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </CourseBody>
                            </Course>
                            <Course>
                                <CourseHead>Automoció</CourseHead>
                                <CourseBody>
                                    <CourseItem>Família transport i manteniment de vehicles</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </CourseBody>
                            </Course>
                            <Course>
                                <CourseHead>Automatització i robòtica industrial</CourseHead>
                                <CourseBody>
                                    <CourseItem>Família d'electricitat i electrònica</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </CourseBody>
                            </Course>
                            <Course>
                                <CourseHead>Laboratori d'anàlisi i control de qualitat</CourseHead>
                                <CourseBody>
                                    <CourseItem>Família de química</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </CourseBody>
                            </Course>
                            <Course>
                                <CourseHead>Programació de la producció en fabricació mecànica</CourseHead>
                                <CourseBody>
                                    <CourseItem>Família de fabricació mecànica</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>

                                </CourseBody>
                            </Course>
                            <Course>
                                <CourseHead>Disseny en fabricació mecànica</CourseHead>
                                <CourseBody>
                                    <CourseItem>Família de fabricació mecànica</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </CourseBody>
                            </Course>
                            <Course>
                                <CourseHead>Laboratori clínic i biomèdic</CourseHead>
                                <CourseBody>
                                    <CourseItem>Família de sanitat</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </CourseBody>
                            </Course>
                        </ListCourse>
                    </SectionBody>
                </Section>
            </Wrapper>
        </Content>
    );
}

export default OfertaEducativaCFGS;