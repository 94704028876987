import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import { ItinerariEnum, RoleEnum } from '../../../constants/enums';

import 'react-datepicker/dist/react-datepicker.css'
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../../../context/user-provider';
import { IStartSchool } from '../../../types/StartSchool';
import { StartSchoolListApi } from '../../../api/start-school-list';

const Content = styled.div`
    padding: 24px;
`;

const FormWrapper = styled.div`
    display:flex;
    justify-content: center;
    align-items: initial;
    flex-direction: column;
    gap: 9px;
    padding-bottom: 24px;
`;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
`;

const Label = styled.label`
    font-weight: bold;
    font-size: 14px;
    display: block;
`;

const FormItemWrapper = styled.div``;

const DatePickerHour = styled.input``;

export interface StartSchoolAddProps {
    onCreate: () => void;
    onClose: () => void;
    open: boolean;
    start: IStartSchool | null;
}


export function StartSchoolAdd(props: StartSchoolAddProps) {
    const [level, setLevel] = useState<string | undefined | null>(props.start?.Level);
    const [itinerari, setItinerari] = useState<ItinerariEnum | undefined | null>(props.start?.Itinerari);
    const [dateStudent, setDateStudent] = useState<any>(props.start?.DateStudent);
    const [dateFamily, setDateFamily] = useState<any>(props.start?.DateFamily);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const user = useContext(UserContext);

    useEffect(() => {
        if (!success) return;
        let timer = setTimeout(() => setSuccess(false), 2000);
        return () => { clearTimeout(timer); };
    }, [success]);

    useEffect(() => {
        if (!error) return;
        let timer = setTimeout(() => setError(false), 2000);
        return () => { clearTimeout(timer); };
    }, [error]);

    const handleSave = async () => {
        if (itinerari === ItinerariEnum.default || !level || !dateStudent) {
            alert('Tots els camps son obligatoris');
            return;
        }

        const pre: IStartSchool = {
            id: props.start?.id ?? uuidv4(),
            Itinerari: itinerari ?? ItinerariEnum.eso,
            Level: level ?? '',
            DateStudent: dateStudent ?? '',
            DateFamily: dateFamily ?? null,
        };

        if (props.start?.id !== null) {
            StartSchoolListApi.Update(pre)
                .then((cred: any) => {
                    setSuccess(true);
                    props.onCreate()
                })
                .catch((error) => {
                    console.log(error);
                    setError(error);
                });
            return;
        }

        StartSchoolListApi.Create(pre)
            .then((cred: any) => {
                setSuccess(true);
                props.onCreate()
            })
            .catch((error) => {
                console.log(error);
                setError(error);
            });
    };

    return (
        <Modal show={props.open} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Nou</Modal.Title>
            </Modal.Header>
            <Content>
                <FormWrapper>
                    <FormItemWrapper>
                        <Label>Estudis</Label>
                        <Form.Select onChange={(e) => setItinerari(parseInt(e.target.value))}>
                            <option value={ItinerariEnum.eso}>ESO</option>
                            <option value={ItinerariEnum.batxillerat} >Batxillerat</option>
                            <option value={ItinerariEnum.cfgm} >CFGM</option>
                            <option value={ItinerariEnum.cfgs} >CFGS</option>
                        </Form.Select>
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Nivell</Label>
                        <Form.Control
                            disabled={user?.role !== RoleEnum.superadmin}
                            as="textarea"
                            rows={2}
                            value={level ?? ''}
                            onChange={e => setLevel(e.target.value)} />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Rebuda alumnat</Label>
                        <DatePickerHour
                            type="datetime-local"
                            disabled={user?.role !== RoleEnum.superadmin}
                            width={100}
                            value={dateStudent}
                            onChange={(e) => setDateStudent(e.target.value)}
                        />
                    </FormItemWrapper>
                    <FormItemWrapper>
                        <Label>Reunió familia</Label>
                        <DatePickerHour
                            type="datetime-local"
                            disabled={user?.role !== RoleEnum.superadmin}
                            value={dateFamily}
                            onChange={(e) => setDateFamily(e.target.value)}
                        />
                    </FormItemWrapper>
                </FormWrapper>
                <Modal.Footer>
                    <ButtonStyled onClick={handleSave}>
                        Desar
                    </ButtonStyled>
                </Modal.Footer>
                {error && <Alert variant="danger">Error al desar el registre</Alert>}
                {success && <Alert variant="success">S’ha modificat correctament el registre</Alert>}
            </Content>
        </Modal >
    );
}

