import { arrayRemove, arrayUnion, doc, getDoc, getFirestore, setDoc, updateDoc, } from "firebase/firestore";
import { firestore } from "../config/firebase";

import { IStartSchool } from "../types/StartSchool";


export namespace StartSchoolListApi {

    const table = 'start-school-list';
    const document = 'list';
    const db = getFirestore();

    export const CreateAll = async (list: IStartSchool[]) => {
        const docRef = doc(db, table, document);
        await setDoc(docRef, { list: list });
    }

    export const Create = async (start: IStartSchool) => {
        const docRef = doc(db, table, document);
        await updateDoc(docRef, { list: arrayUnion(start) });
    }

    export const Delete = async (start: IStartSchool) => {
        const docRef = doc(db, table, document);
        await updateDoc(docRef, { list: arrayRemove(start) });
    }

    export const Update = async (start: IStartSchool) => {
        const docRef = doc(firestore, table, document);
        const result = await getDoc(docRef);
        const data = result.data() as any;
        const found = (data.list as IStartSchool[]).find(x => x.id === start.id);
        if (!found) return null;
        found.Level = start.Level;
        found.Itinerari = start.Itinerari;
        found.DateStudent = start.DateStudent;
        found.DateFamily = start.DateFamily;
        await updateDoc(docRef, { list: data.list });
    }

    export const GetById = async (id: string): Promise<IStartSchool[]> => {
        const result = await getDoc(doc(firestore, table, id));
        return result.data() as IStartSchool[];
    }
}