
import styled from "styled-components";
import useSession from "../hooks/useSession";
import SignIn from "../components/admin/signin";
import SignOut from "../components/admin/signout";
import Main from "../components/admin/main";
import { UserProvider } from "../context/user-provider";
import Wrapper from "../components/wrapper";
import { Helmet } from 'react-helmet';

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
`;

const WrapperTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


const Title = styled.div`
    font-size: 32px;
    font-weight: bold;
`;

export default function Admin() {
    const { user, loading } = useSession();
    if (loading) return <Content />;
    if (!user) return <Content><SignIn /></Content>;
    return (
        <Content>
            <Helmet>
                <title>Administració</title>
                <meta name="robots" content="noindex, nofollow" />
                <meta name="description" content="Administració" />
            </Helmet>
            <UserProvider>
                <Wrapper>
                    <WrapperTitle>
                        <Title>Administració</Title>
                        <SignOut />
                    </WrapperTitle>
                    <Main />
                </Wrapper>
            </UserProvider>
        </Content>
    );
}
