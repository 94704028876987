
import styled from 'styled-components';
import { Auth } from '../../api/auth';
import Wrapper from '../wrapper';
import { useContext } from 'react';
import { UserContext } from "../../context/user-provider";
import { Link } from '../../pages/search';

const Content = styled.div` 
  > div {
    padding: 12px 30px;
    display:flex;
    align-items: center;
    justify-content: right;
  }
`;

const ButtonSignup = styled(Link)``;

function SignOut() {
  const user = useContext(UserContext);

  return (
    <Content>
      <Wrapper>
        <ButtonSignup onClick={() => Auth.SignOut()}>
          {user?.name}, tanca la sessió
        </ButtonSignup>
      </Wrapper>
    </Content>
  );
}

export default SignOut;
