import styled from "styled-components";
import Wrapper from "../wrapper";
import Box from "../box";
import { useContext, useEffect, useState } from "react";
import { IStartSchool } from "../../types/StartSchool";
import { ItinerariEnum } from "../../constants/enums";
import { StartSchoolListApi } from "../../api/start-school-list";
import { DateCustomFormat } from '../../service/date';
import { Form } from "react-bootstrap";
import { MainContext } from "../../context/main-provider";


const Content = styled.div``;

const Title = styled.h2`
  display: flex; 
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  font-weight: bold; 
  color: #b34300;
  margin-bottom: 8px;

  @media(max-width: 1024px){
    font-size: 28px;
    line-height: 28px;
  }

  @media(max-width: 600px){
    font-size: 22px;
    line-height: 22px;
  }
`;

const WrapperBox = styled.div`
  padding: 16px 0;
`;

const Info = styled.div` 
  text-align: center;
  font-size: 18px;
  padding-bottom: 24px;

  @media(max-width: 920px){
    padding-bottom: 21px;
  }

  @media(max-width: 600px){
    font-size: 14px;
  }
`;


const WrapperFilter = styled.div`
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center; 
  align-items: center;

  @media(max-width: 1200px){
    flex-direction: column;
  }
`;

const WrapperControl = styled.div`
  @media(max-width: 1200px){
    width: 300px;
  }
`;

const Label = styled.div`
  font-weight: bold;
`;

const InputText = styled.input`
  padding: 6px 12px;
  outline: none;
  border: initial;
  border-radius: 8px; 
  min-width: 300px;

`;

function StartSchool() {
  const inici = useContext(MainContext);
  const [type, setType] = useState<ItinerariEnum>(ItinerariEnum.default);
  const [level, setLevel] = useState<string | null>(null);
  const [levels, setLevels] = useState<(string | null)[]>([]);
  const [data, setData] = useState<IStartSchool[] | null>();
  const [start, setStart] = useState<IStartSchool | null>(null);

  useEffect(() => {
    if (!level || type === ItinerariEnum.default) return;
    let filter = data?.find(x => x.Itinerari === type && x.Level === level);
    setStart(filter!)
  }, [type, level, data]);

  useEffect(() => {
    if (type === ItinerariEnum.default) return;
    let filter = data?.filter(x => x.Itinerari === type);
    if (!filter) return;
    let filterLevels: (string | null)[] = filter.map(obj => obj.Level);
    setLevels(filterLevels);
  }, [type, data]);

  useEffect(() => {
    if (inici === null || !inici.StartSchool) return;
    StartSchoolListApi.GetById("list")
      .then((x: any) => {
        setData(x.list as IStartSchool[]);
      })
      .catch(x => console.log(x));
  }, [inici]);

  if (inici === null || !inici.StartSchool) return <></>;

  return (
    <Content>
      <Wrapper>
        <Box backgroundColor="#f5c89a">
          <WrapperBox>
            <Title>Informació d’inici de curs {new Date().getFullYear()}/{new Date().getFullYear() + 1}</Title>
            <Info>Totes les rebudes d'alumnat i reunions de famílies tindran lloc a la Sala d’Actes</Info>
            <WrapperFilter>
              <WrapperControl>
                <Label>Estudis</Label>
                <Form.Select onChange={(e) => setType(parseInt(e.target.value))}>
                  <option value={ItinerariEnum.default} >Seleccionar</option>
                  <option value={ItinerariEnum.eso} >ESO</option>
                  <option value={ItinerariEnum.batxillerat} >Batxillerat</option>
                  <option value={ItinerariEnum.cfgm} >CFGM</option>
                  <option value={ItinerariEnum.cfgs} >CFGS</option>
                </Form.Select>
              </WrapperControl>
              <WrapperControl>
                <Label>Curs</Label>
                <Form.Select onChange={(e) => setLevel(e.target.value)}>
                  <option value="" >Seleccionar</option>
                  {levels?.sort((a, b) => a!.localeCompare(b!))
                    .map(x => <option key={x} value={x!}>{x}</option>)}
                </Form.Select>
              </WrapperControl>
              <WrapperControl>
                <Label>Rebuda de l'alumnat</Label>
                <InputText
                  disabled={!start}
                  value={start ? DateCustomFormat(start?.DateStudent!, "#dayWeek #day #month, #hour:#minutsh", "a concretar") : ""}
                  type="text" />
              </WrapperControl>
              <WrapperControl>
                <Label>Reunió de famílies</Label>
                <InputText
                  disabled={!start}
                  value={start ? DateCustomFormat(start?.DateFamily!, "#dayWeek #day #month, #hour:#minutsh", "a concretar") : ""}
                  type="text" />
              </WrapperControl>

            </WrapperFilter>
          </WrapperBox>
        </Box>
      </Wrapper>
    </Content >
  );
}

export default StartSchool;