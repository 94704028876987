import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    
  @font-face {
    font-family: 'Clarity City';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: 
      url('/fonts/ClarityCity-Bold.woff') format('woff2'), 
      url('/fonts/ClarityCity-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Clarity City';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: 
      url('/fonts/ClarityCity-Medium.woff2') format('woff2'), 
      url('/fonts/ClarityCity-Medium.woff') format('woff');
  }

  body {
    font-family: 'Clarity City', sans-serif;
    background-color: #EFF3F6;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #343a40;
  }

  h1 {
    font-size: 38px;
    line-height: 38px;
    font-weight: bold;
  }

  h2 {
    font-size: 32px;
    line-height: 1.25em;
    color: #343a40;
  }

  h3 {
    font-size: 24px;
    line-height: 1.417em;
    color: #343a40;
  }

  p{
    font-size: 24px;
    line-height: 1.417em;
    color: #505050;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .using-icon {
    background: #0fa05c !important; 
    width: 24px !important;
    height: 24px !important;
  }

  .using-icon svg {
    fill: #EFF3F6;
  }

`;